import Vue from 'vue'
import VueRouter, { NavigationGuardNext, Route, RouteConfig, RouteRecord } from 'vue-router'
import store from '@/store'

// Components
import Views from '@/views'
import Layout from '@/components/Layout/Layout.vue'
import AppRouter from '@/components/Layout/AppRouter.vue'
import LayoutAbout from '@/components/Layout/LayoutAbout.vue'
import NotFound from '@/components/NotFound.vue'
const { Login, Roles, Usuarios, Ruta, Rutas, CatalogoRutas, CatalogoRuta, CatalogoRutaPDF, Home, InformeRutas, ImagenesBanner, ReservaRuta, RutasAgencias, Cupones } = Views

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: LayoutAbout,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home,
        meta: {
          requiresAuth: false
        }
      },
      {
        path: 'quienes-somos',
        name: 'QuienesSomos',
        component: () => import('../views/About/QuienesSomos.vue'),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: 'llevate-tu-basura',
        name: 'LlevateTuBasura',
        component: () => import('../views/About/LlevateTuBasura.vue'),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: 'contacto',
        name: 'Contacto',
        component: () => import('../views/About/Contacto.vue'),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: 'catalogo/ruta/:id?',
        name: 'CatalogoRuta',
        component: CatalogoRuta,
        meta: {
          requiresAuth: false
        }
      },
      {
        path: 'rutas-agencias',
        name: 'RutasAgencias',
        component: RutasAgencias,
        meta: {
          requiresAuth: false
        }
      },
      {
        path: 'reserva/ruta/:id?/:estado?/:tipo?',
        name: 'ReservaRuta',
        component: ReservaRuta,
        meta: {
          requiresAuth: false
        }
      },
      {
        path: 'experiencias',
        name: 'CatalogoRutas',
        component: CatalogoRutas,
        meta: {
          requiresAuth: false
        }
      },
      {
        path: 'premios',
        name: 'Premios',
        component: () => import('../views/About/Premios.vue'),
        meta: {
          requiresAuth: false
        }
      }
    ]
  },
  {
    path: '/admin',
    component: Layout,
    children: [
      {
        path: '',
        name: 'AjusteSistema',
        component: () => import('../views/Admin/Home.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'configuracion',
        redirect: 'home',
        component: AppRouter,
        children: [
          {
            path: 'imagenes-banner',
            name: 'ImagenesBanner',
            component: ImagenesBanner,
            meta: {
              requiresAuth: true
            }
          },

          {
            path: 'cupones',
            name: 'Cupones',
            component: Cupones,
            meta: {
              requiresAuth: true
            }
          }
        ]
      },
      {
        path: 'seguridad',
        redirect: 'home',
        component: AppRouter,
        children: [
          {
            path: 'roles',
            name: 'Roles',
            component: Roles,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: 'usuarios',
            name: 'Usuarios',
            component: Usuarios,
            meta: {
              requiresAuth: true
            }
          }
        ]
      },
      {
        path: 'rutas',
        redirect: '/admin/',
        component: AppRouter,
        children: [
          {
            path: 'ruta/:id?',
            name: 'NuevaRuta',
            component: Ruta,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: 'catalogo/ruta/:id?',
            name: 'CatalogoRuta',
            component: CatalogoRuta,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: 'mis-rutas',
            name: 'MisRutas',
            component: Rutas,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: 'premios',
            name: 'Premios',
            component: () => import('../views/Rutas/Premios.vue'),
            meta: {
              requiresAuth: false
            }
          }
        ]
      },
      {
        path: 'informes',
        redirect: '/admin/',
        component: AppRouter,
        children: [
          {
            path: 'rutas',
            name: 'InformeRutas',
            component: InformeRutas,
            meta: {
              requiresAuth: true
            }
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/pdf/:id?',
    name: 'PDF',
    component: CatalogoRutaPDF,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '*',
    component: LayoutAbout,
    redirect: '404',
    children: [{ path: '404', component: NotFound }]
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
  next()
  if (to.matched.some((record: RouteRecord) => record.meta.requiresAuth)) {
    const isAuthenticated = store.getters['auth/isAuthenticated']
    // const menu = store.getters['auth/menu']
    // const whiteList = ['/contratista/workflow/:id?', '/auth-redirect']
    if (!isAuthenticated) {
      if (to.fullPath !== '/') {
        next({ path: '/login' })
        // next({ path: '/login', query: { redirect: to.fullPath } })
      } else {
        next({ path: '/login' })
      }
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
