














































































































































import Vue from 'vue'

import { mapActions } from 'vuex'

export default Vue.extend({
  name: 'Login',
  data() {
    return {
      slides: [
        'https://firebasestorage.googleapis.com/v0/b/ecocaminantes-a4bde.appspot.com/o/Imagenes%2FHome%2FDSC_0954.jpg?alt=media&token=a8f61439-dcec-461c-978b-af96aae8ac4c',
        'https://firebasestorage.googleapis.com/v0/b/ecocaminantes-a4bde.appspot.com/o/Imagenes%2FHome%2FGOPR2574.jpg?alt=media&token=d6086100-3125-404f-b027-d9ea39a7ee8f',
        'https://firebasestorage.googleapis.com/v0/b/ecocaminantes-a4bde.appspot.com/o/Imagenes%2FHome%2FGOPR3267.jpg?alt=media&token=fe946860-903a-4f04-bd75-9fd880edb23d'
      ],

      formClave: {
        dialog: false,
        loading: false,
        valid: true,
        record: { email: '' },
        rules: {
          email: [
            (v: any) =>
              !!/(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/.test(v) ||
              'Ingrese un correo electrónico valido'
          ],
          required: [(v: any) => !!v || 'Este campo es requerido']
        }
      },

      form: {
        loading: false,
        dialog: false,
        valid: false,
        record: { username: '', password: '' },
        // record: { username: 'luis.daniel.mendez@correounivalle.edu.co', password: 'luis.daniel.mendez@correounivalle.edu.co' },
        rules: {
          email: [
            (v: any) =>
              !!/(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/.test(v) ||
              'Ingrese un correo electrónico valido'
          ],
          required: [(v: any) => !!v || 'Este campo es requerido']
        }
      }
    }
  },

  computed: {
    formRecord(): any {
      return this.$refs.formRecord as Vue & { validate: () => boolean; reset: () => void }
    },

    formRecordClave(): any {
      return this.$refs.formRecordClave as Vue & { validate: () => boolean; reset: () => void }
    }
  },

  methods: {
    ...mapActions({ logout: 'auth/logout', login: 'auth/login', forgotPassword: 'auth/forgotPassword' }),

    async submit() {
      if (this.formRecord.validate()) {
        this.form.loading = true
        const { login } = this
        await login({ username: this.form.record.username, password: this.form.record.password, vm: this })
        this.form.loading = false
      }
    },

    async _forgotPassword() {
      if (this.formRecordClave.validate()) {
        this.formClave.loading = true
        const { forgotPassword } = this
        await forgotPassword({ email: this.formClave.record.email, vm: this })
        this.formClave.dialog = false
        this.formClave.loading = false
      }
    }
  }
})
