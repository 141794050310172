import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/analytics'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyA8itTB6LARmMw9RSO3jjArwajdjNA-b1o',
  authDomain: 'ecocaminantes-a4bde.firebaseapp.com',
  databaseURL: 'https://ecocaminantes-a4bde.firebaseio.com',
  projectId: 'ecocaminantes-a4bde',
  storageBucket: 'ecocaminantes-a4bde.appspot.com',
  messagingSenderId: '978496346772',
  appId: '1:978496346772:web:cbdde08c17b4937b727281',
  measurementId: 'G-T2HX9C3C5X'
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.analytics()

export const db = firebase.database()
export const auth = firebase.auth()
export const storage = firebase.storage()
export const ServerValue = firebase.database.ServerValue.TIMESTAMP
