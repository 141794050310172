










































import Vue from 'vue'

export default Vue.extend({
  props: ['heading', 'closeable', 'reloadable', 'fullScreen', 'withTabs', 'tabs', 'onCollapse', 'onReload', 'onClose', 'onChangeTabCallback'],
  data() {
    return {
      activeTab: 0
    }
  },
  methods: {
    onClick(method: string) {
      this.$emit(method)
    },
    onChangeTab(v: number) {
      this.activeTab = v
      this.$emit('onChangeTabCallback', v)
    }
  }
})
