










import Vue from 'vue'

export default Vue.extend({
  props: {
    onSelectFile: Function,
    item: Object,
    index: Number,
    delete: Boolean,
    accept: String
  },
  data() {
    return {
      disabledSelectFile: false,
      file: null
    }
  },
  methods: {
    handleFilesUpload() {
      this.disabledSelectFile = true
      this.onSelectFile(this.file, this.index || 0, this.item || null)
    }
  }
})
