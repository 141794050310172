import { db, ServerValue } from '@/firebase/db'
import { ICuponModel } from '@/store/types'
import store from '@/store'

export default {
  findAll: (): Promise<ICuponModel[]> =>
    new Promise((resolve) => {
      db.ref(`cupones`)
        .orderByChild('createdAt')
        .on('value', (snapshot: any) => {
          let _snapshot: any = []
          if (snapshot.val()) {
            _snapshot = Object.entries(snapshot.val()).map((e) => Object.assign(e[1], { key: e[0], Id: e[0] }))
          }
          resolve((_snapshot as unknown) as ICuponModel[])
        })
    }),

  remove: (data: ICuponModel): Promise<any> => db.ref(`cupones/${data.Id}`).remove(),

  save: (data: any): Promise<any> =>
    new Promise((resolve) => {
      if (data['key']) {
        db.ref(`cupones/${data['key']}`).set({ ...data, updatedAt: ServerValue, UsuarioModificacion: store.getters['auth/username'] }, (snapshot: any) => {
          resolve(snapshot)
        })
      } else {
        db.ref(`cupones`).push({ ...data, createdAt: ServerValue, UsuarioCreacion: store.getters['auth/username'] }, (snapshot: any) => {
          resolve(snapshot)
        })
      }
    })
}
