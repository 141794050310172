


































































































































































































































































import Vue from 'vue'
import moment from 'moment'

/**  Model **/
import { IRutaModel } from '@/store/types'

/**  Service **/
import RutaService from '@/services/RutaService'

export default Vue.extend({
  data() {
    return {
      help: {
        title: 'Rutas Disponibles',
        subtitle: '',
        message: 'Alista tu mochila, tus zapatos viajeros y las mejores vibras para viajar con eco caminantes a lugares verdaderamente mágicos.'
      },

      fab: false,
      tab: 0,
      num: '+573209963324',
      tipo: 'TODOS',

      sedes: [
        { text: 'Cali', value: 'CALI' },
        { text: 'Medellín', value: 'MEDELLIN' }
      ],

      weekends: [
        { text: 'Entre semana', value: true },
        { text: 'Fines de semana', value: false }
      ],

      tipos: [
        { text: 'Caminata ecologia', value: 'Caminata ecologia' },
        { text: 'Viaje grupal', value: 'Viaje grupal' },
        { text: 'Rutas Pet', value: 'Rutas Pet' }
      ],

      dificultades: [
        { text: 'BAJA', value: 'BAJA' },
        { text: 'MEDIA', value: 'MEDIA' },
        { text: 'ALTA', value: 'ALTA' },
        { text: 'EXTREMA', value: 'EXTREMA' }
      ],

      meses: [
        {
          text: 'Enero',
          value: 1
        },
        {
          text: 'Febrero',
          value: 2
        },
        {
          text: 'Marzo',
          value: 3
        },
        {
          text: 'Abril',
          value: 4
        },
        {
          text: 'Mayo',
          value: 5
        },
        {
          text: 'Junio',
          value: 6
        },
        {
          text: 'Julio',
          value: 7
        },
        {
          text: 'Agosto',
          value: 8
        },
        {
          text: 'Septiembre',
          value: 9
        },
        {
          text: 'Octubre',
          value: 10
        },
        {
          text: 'Noviembre',
          value: 11
        },
        {
          text: 'Diciembre',
          value: 12
        }
      ],

      table: {
        show: true,
        filters: {
          sede: [] as any[],
          tipo: [] as any[],
          meses: [] as any[],
          dificultad: [] as any[],
          ruta: [] as any[],
          duracion: 0,
          fecha: '',
          weekend: [] as any[]
        }
      },

      records: [] as IRutaModel[]
    }
  },

  computed: {
    getMeses(): any[] {
      return this.meses.filter((f) => f.value >= moment().month() + 1)
    },

    getRutas(): any[] {
      return this.records
        .map((item) => item.Nombre)
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort()
    },

    filterRecords(): any[] {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      let records = this.records.filter(
        (f) =>
          (!this.table.filters.fecha || f.Fecha === this.table.filters.fecha) &&
          (!this.table.filters.duracion || this.table.filters.duracion === 0 || this.table.filters.duracion.toString() === f.Duracion) &&
          (!this.table.filters.sede || this.table.filters.sede.length <= 0 || this.table.filters.sede.includes(f.Sede)) &&
          (!this.table.filters.tipo || this.table.filters.tipo.length <= 0 || this.table.filters.tipo.includes(f.Tipo)) &&
          (!this.table.filters.ruta || this.table.filters.ruta.length <= 0 || this.table.filters.ruta.includes(f.Nombre)) &&
          (!this.table.filters.weekend || this.table.filters.weekend.length <= 0 || this.table.filters.weekend.includes(f.isWeekday)) &&
          (!this.table.filters.dificultad || this.table.filters.dificultad.length <= 0 || this.table.filters.dificultad.includes(f.Dificultad))
      )

      if (this.table.filters.meses && this.table.filters.meses.length > 0) {
        records = records.filter((f) => {
          const hasMatchingTag = f.Meses.some((tag) => this.table.filters.meses.includes(tag))
          return hasMatchingTag
        })
      }

      return records
    }
  },

  mounted() {
    this.load()
  },

  methods: {
    toTop() {
      this.$vuetify.goTo(0)
    },

    listaEspera(item: IRutaModel) {
      window.open(
        `https://wa.me/${encodeURIComponent(this.num)}?text=${encodeURIComponent(
          `Hola, me gustaría estar en la lista de espera de la RUTA ${item.Nombre} para el día  ${item.FechaCompleta}, quedo atento si se habilita el cupo, gracias.`
        )}`,
        '_blank'
      )
    },

    validateHover(ruta: IRutaModel) {
      if (!ruta.Selected) this.selectedRuta(ruta, true)
    },

    selectedRuta(item: IRutaModel, value: boolean) {
      item.Selected = value
    },

    calcularCupos(item: IRutaModel) {
      if (!item.Reservas) item.Reservas = []

      const Pendiente = item.Reservas.filter((f) => f.Estado === 'Pendiente').length || 0
      const Confirmada = item.Reservas.filter((f) => f.Estado === 'Confirmada').length || 0

      item.CuposPendientes = Pendiente
      item.CuposConfirmados = Confirmada
      item.CuposDisponibles = item.CuposOfertar - Confirmada

      return item
    },

    formatPrice(value: number) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return (
        '$ ' +
        val
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
          .replace(',00', '')
      )
    },

    getLocation() {
      return location.origin + '/#/'
    },

    async load() {
      this.$store.dispatch('app/showLoading')
      const record = (await RutaService.findAll()) as any

      this.records = record.filter((f: IRutaModel) => (f.Estado === 'Disponible' || f.Estado === 'Agotado') && !moment(f.Fecha, 'DD/MM/yyyy').isBefore(moment()))
      this.$store.dispatch('app/hideLoading')
    },

    detail(item: any) {
      this.$router.push(`/catalogo/ruta/${item.Id}`)
    }
  }
})
