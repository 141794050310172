



























import Vue from 'vue'
import AppCardHeading from './AppCardHeading.vue'
import AppCardFooter from './AppCardFooter.vue'
import AppCardContent from './AppCardContent.vue'
import AppSectionLoader from '../AppSectionLoader/AppSectionLoader.vue'

export default Vue.extend({
  props: ['heading', 'colClasses', 'customClasses', 'fullBlock', 'contentCustomClass', 'closeable', 'reloadable', 'fullScreen', 'footer', 'withTabs', 'tabs', 'onChangeTabCallback'],

  data() {
    return {
      reload: false,
      close: false
    }
  },

  components: {
    AppCardHeading,
    AppCardFooter,
    AppCardContent,
    AppSectionLoader
  },

  methods: {
    onCollapse() {
      // var toggleFullscreen = this.$refs.fullscreen as any
      // toggleFullscreen.toggle()
    },
    onReload() {
      this.reload = true
      setTimeout(() => {
        this.reload = false
      }, 1500)
    },
    onClose() {
      this.close = true
    },
    onChangeTab(value: number) {
      this.$emit('onChangeTabCallback', value)
    }
  }
})
