






































































































import Vue from 'vue'

/**  Model **/
import { IRutaModel } from '@/store/types'

/**  Service **/
import RutaService from '@/services/RutaService'

export default Vue.extend({
  data() {
    return {
      help: {
        title: 'Listado de experiencias',
        subtitle: '',
        message: 'En esta página podrás encontrar todas las experiencias disponibles en el sistema.'
      },

      form: {
        loading: true,
        dialog: false,
        valid: false,
        record: {},
        rules: {
          required: [(v: any) => !!v || 'Este campo es requerido']
        }
      },

      table: {
        loading: true,
        filters: {
          search: null,
          Sede: null,
          Estado: null,
          FechaDesde: '',
          FechaHasta: ''
        },
        headers: [
          { text: 'Sede', value: 'Sede', align: 'left' },
          { text: 'Nombre', value: 'Nombre', align: 'left' },
          { text: 'Fecha de la ruta', value: 'Fecha', align: 'left' },
          { text: 'Dificultad', value: 'Dificultad', align: 'left' },
          { text: 'Cupos disponibles', value: 'CuposDisponibles', align: 'left' },
          { text: 'Valor', value: 'ValorTotal', align: 'left' },
          { text: 'Moneda', value: 'Moneda', align: 'left' },
          { text: 'Estado', value: 'Estado', align: 'center' },
          { text: 'Acciones', align: 'center', width: '5%' }
        ],
        records: [] as IRutaModel[],
        rowsPerPage: [5, 10, 20, 25, 30, { text: 'Todos', value: -1 }]
      }
    }
  },

  components: {},

  computed: {
    filterRecords(): any {
      return this.table.records.filter(
        (f: any) =>
          (!this.table.filters.Estado || f.Estado === this.table.filters.Estado) &&
          (!this.table.filters.Sede || f.Sede === this.table.filters.Sede) &&
          (!this.table.filters.FechaDesde || f.Fecha >= this.table.filters.FechaDesde) &&
          (!this.table.filters.FechaHasta || f.Fecha <= this.table.filters.FechaHasta)
      )
    }
  },

  mounted() {
    this.load()
  },

  methods: {
    formatPrice(value: number) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return (
        '$ ' +
        val
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
          .replace(',00', '')
      )
    },

    edit(item: any) {
      console.log(item)
    },

    calcularCupos(item: IRutaModel) {
      if (!item.Reservas) item.Reservas = []

      const Pendiente = item.Reservas.filter((f) => f.Estado === 'Pendiente').length || 0
      const Confirmada = item.Reservas.filter((f) => f.Estado === 'Confirmada').length || 0

      item.CuposPendientes = Pendiente
      item.CuposConfirmados = Confirmada
      item.CuposDisponibles = item.CuposOfertar - Confirmada

      item.PendientesSoporte = item.CuposConfirmados - item.Reservas.filter((f) => f.Estado === 'Confirmada' && f.SoportePago).length || 0
      return item
    },

    async load() {
      this.$store.dispatch('app/showLoading')
      let records = (await RutaService.findAll(false)) as any
      records = records.map((row: IRutaModel) => this.calcularCupos(row))
      this.table.records = records.filter((f: IRutaModel) => f.Estado === 'Disponible')
      this.$store.dispatch('app/hideLoading')
    },

    reservar(item: any) {
      const routeData = this.$router.resolve({ path: `/reserva/ruta/${item.Id}/XTZ/AGENCIA` })
      window.open(routeData.href)
    }
  }
})
