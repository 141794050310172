




import Vue from 'vue'

export default Vue.extend({
  props: [],

  data() {
    return {}
  },

  components: {},

  methods: {}
})
