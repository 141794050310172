






































































































































































































































































































































































































































































































































import Vue from 'vue'
import { v4 as uuidv4 } from 'uuid'

/**  Model **/
import { IRutaModel, IRutaHorarioModel, IRutaResevaModel, ICuponModel } from '@/store/types'

/**  Service **/
import RutaService from '@/services/RutaService'
import CuponesService from '@/services/CuponesService'

/**  Views **/

export default Vue.extend({
  props: {
    ruta: {
      type: Object,
      default: { Horarios: [] as IRutaHorarioModel[] } as IRutaModel
    },

    estado: {
      type: String,
      default: ''
    },

    tipo: {
      type: String,
      default: ''
    },

    id: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      help: {
        title: 'Registrar Ruta',
        subtitle: '',
        message: 'En esta página podrás registrar tus rutas.'
      },

      panel: [] as any,

      rutas: [] as IRutaModel[],

      cupones: [] as ICuponModel[],

      msg: '',

      num: '+573209963324',

      formasPago: [
        // {
        //   Text: 'Daviplata',
        //   Value: 'Daviplata - 3167003712 - Gina Arias'
        // },
        {
          Text: 'Nequi',
          Value: 'Nequi - 3134492451 - Luis Mendez'
        },
        {
          Text: 'Bancolombia',
          Value: 'Bancolombia - Cuenta de ahorro - 82951365137 - Luis Daniel Mendez - CC.1.112.481.867'
        },
        {
          Text: 'Tarjeta de Credito',
          Value: 'Tarjeta de Credito - Solicita el link para tu pago'
        }
      ],

      modalidadesPago: [
        {
          Text: 'Al contado',
          Value: 'Al contado'
        },
        {
          Text: 'A cuotas',
          Value: 'A cuotas'
        }
      ],

      formReserva: {
        loading: true,
        dialog: false,
        dialogConfirm: false,
        valid: false,
        recordConfirm: {} as any,
        record: {
          ReferidoPor: null,
          Identificacion: null,
          Nombre: null,
          Apellido: null,
          Ciudad: null,
          Edad: null,
          Celular: null,
          Email: null,
          Alimentacion: null,
          AlimentacionAlmuerzo: null,
          AlimentacionCena: null,
          NotaCliente: null,
          CantidadCupo: 1,
          CantidadCuotas: 1,
          CantidadAcompanantes: 0,
          ModalidadPago: 'Al contado',
          Invitados: [],
          NombreMascota: null,
          DisponiblesTransporte: false,
          CuposDisponiblesTransporte: 0,
          Instagram: null,
          MedioContacto: null,
          Genero: null
        } as any,
        rules: {
          required: [(v: any) => !!v || 'Este campo es requerido'],
          email: [(v: any) => /.+@.+\..+/.test(v) || 'El email debe ser válido']
        }
      }
    }
  },

  computed: {
    formRecord() {
      return this.$refs.formRecord as Vue & { validate: () => boolean; reset: () => void }
    }
  },

  mounted() {
    this.load()
  },

  methods: {
    all() {
      this.panel = [...Array(this.formReserva.record.CantidadAcompanantes).keys()].map((k, i) => i)
    },

    none() {
      this.panel = []
    },

    agregarInvitado() {
      this.formReserva.record.CantidadAcompanantes += 1
      this.formReserva.record.Invitados.push(({
        ReferidoPor: null,
        Identificacion: null,
        Nombre: null,
        Apellido: null,
        Ciudad: null,
        Edad: null,
        Celular: null,
        Email: null,
        Alimentacion: null,
        AlimentacionAlmuerzo: null,
        AlimentacionCena: null,
        NotaCliente: null,
        CantidadCupo: 1,
        CantidadCuotas: this.ruta.CantidadCuotas || 1,
        CantidadAcompanantes: 0,
        ModalidadPago: 'Al contado',
        NombreMascota: null,
        DisponiblesTransporte: false,
        CuposDisponiblesTransporte: 0,
        Genero: null,
        Instagram: null,
        MedioContacto: null,
        Invitados: []
      } as unknown) as IRutaResevaModel)
      this.all()
    },

    listaEspera(item: IRutaModel) {
      window.open(
        `https://wa.me/${encodeURIComponent(this.num)}?text=${encodeURIComponent(
          `Hola ECOcaminantes, me gustaría estar en la lista de espera de la RUTA *${item.Nombre}* para el día ${item.Fecha}, quedo atento si se habilita el cupo, gracias.`
        )}`,
        '_blank'
      )
    },

    calcularCupos(item: IRutaModel) {
      if (!item.Reservas) item.Reservas = []

      const Pendiente = item.Reservas.filter((f) => f.Estado === 'Pendiente').length || 0
      const Confirmada = item.Reservas.filter((f) => f.Estado === 'Confirmada').length || 0

      item.CuposPendientes = Pendiente
      item.CuposConfirmados = Confirmada
      item.CuposDisponibles = item.CuposOfertar - Confirmada

      return item
    },

    searchIdentificacion() {
      let found = false
      if (this.formReserva.record.Identificacion) {
        this.$store.dispatch('app/showLoading')
        this.rutas.map((row) => {
          if (row.Reservas && found == false) {
            const reserva = row.Reservas.find((f) => f.Identificacion === this.formReserva.record.Identificacion)
            if (reserva) {
              this.formReserva.record.Genero = reserva?.Genero || null
              this.formReserva.record.Nombre = reserva.Nombre
              this.formReserva.record.Apellido = reserva.Apellido
              this.formReserva.record.Ciudad = reserva.Ciudad
              this.formReserva.record.Edad = reserva.Edad
              this.formReserva.record.Celular = reserva.Celular
              this.formReserva.record.Email = reserva.Email
              this.formReserva.record.Instagram = reserva?.Instagram || null
              this.formReserva.record.MedioContacto = reserva?.MedioContacto || null
              found = true
            }
          } else {
            return
          }
        })
        this.$store.dispatch('app/hideLoading')
      }
    },

    searchIdentificacion2(item: any) {
      let found = false
      if (item.Identificacion) {
        this.$store.dispatch('app/showLoading')
        this.rutas.map((row) => {
          if (row.Reservas && found == false) {
            const reserva = row.Reservas.find((f) => f.Identificacion === item.Identificacion)
            if (reserva) {
              item.Genero = reserva?.Genero || null
              item.Nombre = reserva.Nombre
              item.Apellido = reserva.Apellido
              item.Ciudad = reserva.Ciudad
              item.Edad = reserva.Edad
              item.Celular = reserva.Celular
              item.Email = reserva.Email
              item.Instagram = reserva?.Instagram || null
              item.MedioContacto = reserva?.MedioContacto || null
              found = true
            }
          } else {
            return
          }
        })
        this.$store.dispatch('app/hideLoading')
      }
    },

    getLocation() {
      return location.origin + '/#/'
    },

    formatPrice(value: number) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return (
        '$ ' +
        val
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
          .replace(',00', '')
      )
    },

    async getRutas() {
      this.rutas = (await RutaService.findAll()) as any
      this.cupones = (await CuponesService.findAll()) as any
    },

    async load() {
      this.$store.dispatch('app/showLoading')

      this.formReserva.record.CantidadCuotas = this.ruta.CantidadCuotas || 1

      if (this.id) {
        this.formReserva.record = (await RutaService.findReservaById({ key: this.id, IdRuta: this.ruta.Id } as any)) as any
      }

      this.help.title = this.ruta.Nombre
      this.help.message = ''
      this.getRutas()
      this.$store.dispatch('app/hideLoading')
    },

    guid() {
      let uud = uuidv4().toString()
      return uud.substring(0, 8)
    },

    confirm() {
      window.open(`https://wa.me/${encodeURIComponent(this.num)}?text=${encodeURIComponent(this.msg)}`, '_blank')
      this.formReserva.dialogConfirm = false
      this.reset()
    },

    async isValid() {
      await this.getRutas()
      const response = this.rutas.find((f) => f.Id === this.ruta.Id) as IRutaModel
      this.calcularCupos(response)
      const reserva = response.Reservas.find((f) => f.Identificacion === this.formReserva.record.Identificacion)

      let valid = true

      const newR = this.id ? false : true

      if (response.CuposDisponibles <= 0 && this.estado !== 'ADMIN') {
        valid = false
        this.$store.dispatch('app/alertPrompt', {
          text: `Ruta agotada. por favor incribete en la lista de espera`,
          type: 'error'
        })
        this.listaEspera(this.ruta)
      } else if (reserva && newR) {
        valid = false
        this.$store.dispatch('app/alertPrompt', {
          text: `Usted ya cuenta con una reserva (No. ${reserva.Numero}) para esta ruta, por favor comuniquese con su asesor para verificar esta.`,
          type: 'error'
        })
      }

      if (this.formReserva.record.IndCupon) {
        if (!this.formReserva.record.Cupon) {
          valid = false
          this.$store.dispatch('app/alertPrompt', {
            text: `Por favor ingrese un código de descuento.`,
            type: 'error'
          })
        } else if (!this.cupones.find((f: ICuponModel) => f.Codigo === this.formReserva.record.Cupon)) {
          valid = false
          this.$store.dispatch('app/alertPrompt', {
            text: `El código de descuento ${this.formReserva.record.Cupon} no es valido.`,
            type: 'error'
          })
        }
      }

      return valid
    },

    async save() {
      const estado = this.estado === 'ADMIN' ? this.formReserva.record.Estado : this.estado === 'XTZ' ? 'Confirmada' : 'Pendiente'
      const valid = await this.isValid()
      let ValorDescuento = 0
      let PorcentajeDescuento = 0
      let ValorTotal = this.ruta.ValorTotal

      if (this.formReserva.record.IndCupon) {
        let cupon = this.cupones.find((f: ICuponModel) => f.Codigo === this.formReserva.record.Cupon)
        if (cupon) {
          PorcentajeDescuento = cupon.Porcentaje
          ValorDescuento = (ValorTotal * cupon.Porcentaje || 0) / 100
          ValorTotal = ValorTotal - ValorDescuento
        }
      } else {
        this.formReserva.record.Cupon = ''
      }

      if (this.formRecord.validate() && valid) {
        const newR = this.id ? false : true
        const numero = this.id ? this.id : this.guid()
        this.$store.dispatch('app/showLoading')
        let model = {
          IdRuta: this.ruta.Id,
          ...this.formReserva.record,
          Id: numero,
          Estado: estado,
          Valor: ValorTotal,
          ValorReal: this.ruta.ValorTotal,
          PorcentajeDescuento: PorcentajeDescuento,
          ValorDescuento: ValorDescuento
        }

        if (newR) {
          model.key = numero
          model.Numero = numero
        }

        await RutaService.saveReserva({ ...model })

        if (this.estado !== 'ADMIN') {
          // eslint-disable-next-line prettier/prettier
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          this.formReserva.record.Invitados.forEach(async (row: any) => {
            row.Estado = estado
            row.Numero = this.guid()
            row.IdReservaPadre = model.Numero
            row.Valor = ValorTotal
            row.ValorReal = this.ruta.ValorTotal
            row.PorcentajeDescuento = PorcentajeDescuento
            row.ValorDescuento = ValorDescuento
            await RutaService.saveReserva({ key: this.ruta.Id, IdRuta: this.ruta.Id, ...row })
          })
        }

        this.$store.dispatch('app/hideLoading')

        if (estado === 'Confirmada' || this.estado === 'ADMIN') {
          this.formRecord.reset()
          this.$store
            .dispatch('app/alertPrompt', {
              text: `Número reserva: ${model.Numero}, Proceso realizado correctamente.`,
              type: 'success'
            })
            .then(() => {
              location.reload()
            })
        } else {
          const routeData = this.$router.resolve({ path: `/catalogo/ruta/${this.ruta.Id}` })

          this.msg = `${location.origin + '/' + routeData.href}\r\n\r\nHola ECOcaminantes, aquí están los detalles de mi reserva:\r\r\n*Número reserva:* # ${
            model.Numero
          }\r\n\r\nNombre de la ruta:\r\n*${this.ruta.Nombre}*\r\n*Fecha:* ${model.FechaFinal}\r\n*Cantidad de cupos:* ${model.CantidadCupo +
            model.CantidadAcompanantes}\r\n*Precio ruta:* ${this.formatPrice(model.ValorReal)}\r\n*Código de descuento:* ${model.Cupon}\r\n*Descuento aplicado:* ${this.formatPrice(
            model.ValorDescuento || 0
          )}\r\n\r\n*Método de Pago:* ${model.FormaPago}\r\n\r\n*Modalidad de Pago:* ${model.ModalidadPago || ''}\r\n*Cantidad de cuotas:* ${model.CantidadCuotas ||
            '1'}\r\n\r\n*Detalles del Cliente*\r\n`

          this.msg += `Nombre: ${model.Nombre}\r\n${model.NombreMascota ? `Nombre de Mascota: ${model.NombreMascota}` : ''}\r\nCiudad de residencia: ${model.Ciudad}\r\nPunto de salida: ${
            model.PuntoSalida
          }\r\n${
            this.ruta.TipoTranporte === 'Transporte Propio' && this.ruta.LogisticaTransporte
              ? `Tipo de vehículo: ${model.TipoTransporte}\r\n¿Cupos disponibles para transportar otros viajeros ?: ${model.CuposDisponiblesTransporte}`
              : ''
          }\r\n*Alimentación*\r\nDesayuno: ${model.Alimentacion || ''}\r\nAlmuerzo: ${model.AlimentacionAlmuerzo || ''}\r\nCena: ${model.AlimentacionCena || ''}\r\nEdad:${model.Edad}\r\nCelular: ${
            model.Celular
          }\r\nEmail: ${model.Email}\r\n\r\n`

          this.formReserva.record.Invitados.forEach((row: any, index: number) => {
            this.msg += `———————————————————\r\n\r\n*Acompañante # ${index + 1}:*\r\n*Número reserva:* # ${row.Numero}\r\nNombre: ${row.Nombre}\r\n${
              row.NombreMascota ? `Nombre de Mascota: ${row.NombreMascota}` : ''
            }\r\nCiudad de residencia: ${row.Ciudad}\r\nPunto de salida: ${row.PuntoSalida}\r\n${
              this.ruta.TipoTranporte === 'Transporte Propio' && this.ruta.LogisticaTransporte
                ? `Tipo de vehículo: ${row.TipoTransporte}\r\n¿Cupos disponibles para transportar otros viajeros ?: ${row.CuposDisponiblesTransporte}`
                : ''
            }\r\n*Alimentación*\r\nDesayuno: ${row.Alimentacion || ''}\r\nAlmuerzo: ${row.AlimentacionAlmuerzo || ''}\r\nCena: ${row.AlimentacionCena || ''}\r\nEdad: ${row.Edad}\r\nCelular: ${
              row.Celular
            }\r\nEmail: ${row.Email}\r\n\r\n*Método de Pago:* ${row.FormaPago}\r\n*Modalidad de Pago:* ${row.ModalidadPago || ''}\r\n*Cantidad de cuotas:* ${row.CantidadCuotas || '1'}\r\n`
          })

          this.msg += `———————————————————\r\n\r\n*Valor por persona:* ${this.formatPrice(model.Valor)}\r\n*Total: ${this.formatPrice(
            model.Valor * (model.CantidadCupo + model.CantidadAcompanantes)
          )}* \r\n*Código de descuento:* ${model.Cupon}\r\n*Descuento total aplicado:* ${this.formatPrice(
            (model.ValorDescuento || 0) * (model.CantidadCupo + model.CantidadAcompanantes)
          )}\r\n\r\n*Nota del cliente:* ${model.NotaCliente || ''}\r\n\r\nGracias!`

          this.formReserva.recordConfirm = {
            ...this.formReserva.record,
            msg: this.msg,
            NombreRuta: this.ruta.Nombre,
            IdRuta: this.ruta.Id,
            Fecha: this.ruta.Fecha,
            Numero: model.Numero
          } as any

          this.formReserva.dialogConfirm = true
        }
      }
    },

    reset() {
      location.reload()
      this.formRecord.reset()
      this.formReserva.dialog = false
    },

    cancel() {
      this.$router.push('/experiencias')
    }
  }
})
