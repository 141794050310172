











































































































import Vue from 'vue'

/**  Model **/
import { IRutaModel, IRutaResevaModel } from '@/store/types'

/**  Service **/
import RutaService from '@/services/RutaService'

export default Vue.extend({
  data() {
    return {
      help: {
        title: 'Informe de rutas',
        subtitle: '',
        message: 'En esta página podrás encontrar el informe de las rutas radicadas en el sistema.'
      },

      rutas: [] as IRutaModel[],

      table: {
        loading: true,
        filters: {
          search: null,
          Sede: null,
          Estado: null,
          FechaDesde: '',
          FechaHasta: ''
        },
        headers: [
          { text: 'Fecha de la ruta', value: 'Fecha', align: 'left', width: '15%' },
          { text: 'Nombre', value: 'Nombre', align: 'left', width: '15%' },
          { text: 'Cupos ofertardos', value: 'CuposOfertar', align: 'left', width: '10%' },
          { text: 'Cupos confirmados', value: 'CuposConfirmados', align: 'left', width: '10%' },
          { text: 'Valor total experiencia', value: 'ValorTotalExperiencia', align: 'left', width: '10%' },
          { text: 'Ingresos', value: 'Ingresos', align: 'left', width: '15%' },
          { text: 'Total gastos', value: 'TotalGastos', align: 'left', width: '15%' },
          { text: 'Utilidad', value: 'Utilidad', align: 'left', width: '15%' },
          { text: 'Margen (%)', value: 'Margen', align: 'left', width: '15%' },
          { text: 'Estado', value: 'Estado', align: 'center', width: '10%' },
          { text: 'Acciones', align: 'center', width: '5%' }
        ],
        records: [] as any[],
        rowsPerPage: [5, 10, 20, 25, 30, { text: 'Todos', value: -1 }]
      }
    }
  },

  components: {},

  computed: {
    filterRecords(): any {
      return this.table.records.filter(
        (f: any) =>
          (!this.table.filters.Estado || f.Estado === this.table.filters.Estado) &&
          (!this.table.filters.Sede || f.Sede === this.table.filters.Sede) &&
          (!this.table.filters.FechaDesde || f.Fecha >= this.table.filters.FechaDesde) &&
          (!this.table.filters.FechaHasta || f.Fecha <= this.table.filters.FechaHasta)
      )
    }
  },

  mounted() {
    this.load()
  },

  methods: {
    informeRutas() {
      let informe = [] as any
      this.rutas
        .filter((f) => f.Estado !== 'Pendiente')
        .forEach((ruta: any) => {
          let TotalGastos = 0
          let Utilidad = 0
          let Margen = 0
          let Ingresos = 0
          let ValorTotalExperiencia = 0

          ruta.Gastos.map((row: any) => {
            TotalGastos += row.Valor
          })

          ruta.Reservas.filter((f: any) => f.Estado === 'Confirmada').map((row: any) => {
            Ingresos += row.ValorReal
          })

          ValorTotalExperiencia = parseFloat(ruta.ValorTransporte || 0) + parseFloat(ruta.ValorNeto || 0)

          Utilidad = Ingresos - TotalGastos
          Margen = Ingresos === 0 ? 0 : Utilidad / Ingresos

          informe.push({
            Sede: ruta.Sede,
            Fecha: ruta.Fecha,
            Nombre: ruta.Nombre,
            CuposOfertar: ruta.CuposOfertar,
            CuposConfirmados: ruta.CuposConfirmados,
            ValorTotalExperiencia: ValorTotalExperiencia,
            TotalGastos: TotalGastos,
            Ingresos: Ingresos,
            Utilidad: Utilidad,
            Margen: Margen,
            Estado: ruta.Estado
          })
        })

      return informe
    },

    calcularCupos(item: IRutaModel) {
      if (!item.Reservas) item.Reservas = []
      if (!item.Gastos) item.Gastos = []

      const Pendiente = item.Reservas.filter((f) => f.Estado === 'Pendiente').length || 0
      const Confirmada = item.Reservas.filter((f) => f.Estado === 'Confirmada').length || 0

      item.CuposPendientes = Pendiente
      item.CuposConfirmados = Confirmada
      item.CuposDisponibles = item.CuposOfertar - Confirmada

      return item
    },

    formatPrice(value: number) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return '$ ' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },

    async load() {
      this.$store.dispatch('app/showLoading')
      let records = (await RutaService.findAll()) as any
      this.rutas = records.map((row: IRutaModel) => this.calcularCupos(row))
      this.table.records = this.informeRutas()
      this.$store.dispatch('app/hideLoading')
    },

    edit(item: any) {
      const routeData = this.$router.resolve({ path: '/admin/rutas/ruta/' + item.Id })
      window.open(routeData.href)
    },

    async download() {
      /* eslint-disable @typescript-eslint/no-var-requires */
      const ExcelJS = require('exceljs')

      /* eslint-disable @typescript-eslint/no-var-requires */
      const FileSaver = require('file-saver')

      const workbook = new ExcelJS.Workbook()
      workbook.creator = 'LAB'
      workbook.lastModifiedBy = 'LAB'
      workbook.created = new Date()
      workbook.modified = new Date()
      workbook.lastPrinted = new Date()

      const worksheet = workbook.addWorksheet('InformeRutas')

      const fields = ['Fecha', 'Nombre', 'CuposOfertar', 'CuposConfirmados', 'ValorTotalExperiencia', 'Ingresos', 'TotalGastos', 'Utilidad', 'Margen', 'Estado']

      worksheet.addRow(fields)

      //parseFloat(form.record.ValorTransporte || 0) + parseFloat(form.record.ValorNeto || 0)
      this.table.records.forEach((row: any) => {
        let _row = [] as any
        fields.forEach((column: string) => {
          _row.push(row[column])
        })

        worksheet.addRow(_row)
      })

      const fileName = 'InformeRutas.xlsx'
      workbook.xlsx.writeBuffer().then((data: any) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        FileSaver.saveAs(blob, fileName)
      })
    }
  }
})
