




















































































































































import Vue from 'vue'

/**  Model **/
import { IRolModel, IRolOpcionMenuModel } from '@/store/types'

/**  Service **/
import RolService from '@/services/RolService'
import OpcionMenuService from '@/services/OpcionMenuService'

export default Vue.extend({
  data() {
    return {
      help: {
        title: 'Perfiles de usuario',
        subtitle: '',
        message: 'En esta página podrás encontrar todos los perfiles de usuarios que han sido creados.'
      },

      form: {
        loading: true,
        dialog: false,
        valid: false,
        record: {} as IRolModel,
        rules: {
          required: [(v: any) => !!v || 'Este campo es requerido']
        }
      },

      permisos: [],

      table: {
        loading: true,
        filters: {
          search: null,
          usuario: ''
        },
        headers: [
          { text: 'Nombre', value: 'Nombre', align: 'left' },
          { text: 'Descripción', value: 'Descripción', align: 'left' },
          { text: 'Control total', value: 'ControlTotal', align: 'center' },
          { text: 'Rol editable', value: 'IndicadorEditable', align: 'center' },
          { text: 'Activo', value: 'Activo', align: 'center' },
          { text: 'Creación', value: 'UsuarioCreacion', align: 'left' },
          { text: 'Ultima modificación', value: 'UsuarioModificacion', align: 'left' },
          { text: 'Acciones', align: 'center' }
        ],
        records: [],
        rowsPerPage: [5, 10, 20, 25, 30, { text: 'Todos', value: -1 }]
      }
    }
  },

  components: {},

  computed: {
    filterRecords(): any {
      return this.table.records.filter((f: any) => !this.table.filters.usuario || f.usuarios.indexOf(this.table.filters.usuario) !== -1)
    },

    formRecord() {
      return this.$refs.formRecord as Vue & { validate: () => boolean; reset: () => void }
    }
  },

  mounted() {
    this.load()
  },

  methods: {
    async load() {
      this.$store.dispatch('app/showLoading')
      const records = await RolService.findAll()
      this.table.records = records as any
      const permisos = await OpcionMenuService.findAll()
      this.permisos = permisos as any
      this.$store.dispatch('app/hideLoading')
    },

    add() {
      this.form.record = { Activo: true, RolOpcionMenu: [] as any } as IRolModel
      this.form.dialog = true
    },

    async edit(item: IRolModel) {
      // this.$store.dispatch('app/showLoading')
      // let record = (await RolService.findById(item)) as any
      // record.RolOpcionMenu = record.RolOpcionMenu.map((row: IRolOpcionMenuModel) => {
      //   return row.OpcionMenuId
      // })
      // this.form.record = record
      // this.form.dialog = true
      // this.$store.dispatch('app/hideLoading')
    },

    async save() {
      // if (this.formRecord.validate()) {
      //   this.$store.dispatch('app/showLoading')
      //   let response = null as any
      //   let model = this.form.record
      //   model.RolOpcionMenu = model.RolOpcionMenu.map((row: any) => {
      //     return ({ OpcionMenuId: row } as unknown) as IRolOpcionMenuModel
      //   })
      //   response = await RolService.save({ ...model } as IRolModel)
      //   this.$store.dispatch('app/hideLoading')
      //   if (response.isAxiosError) return
      //   this.form.record = { Activo: true } as IRolModel
      //   this.form.dialog = false
      //   this.load()
      //   this.$store.dispatch('app/alertPrompt', {
      //     text: 'Proceso realizado correctamente.',
      //     type: 'success'
      //   })
      // }
    },

    reset() {
      this.form.dialog = false
      this.formRecord.reset()
    }
  }
})
