














































































































































import Vue from 'vue'

/**  Model **/
import { IRutaModel } from '@/store/types'

/**  Views **/
import FormularioRuta from '@/views/Rutas/FormularioRuta.vue'

/**  Service **/
import RutaService from '@/services/RutaService'

/**  Views **/

export default Vue.extend({
  components: {
    FormularioRuta
  },

  data() {
    return {
      help: {
        title: 'Registrar Ruta',
        subtitle: '',
        message: 'En esta página podrás registrar tus rutas.'
      },

      formReserva: { dialog: false },

      num: '+573209963324',

      ruta: {} as IRutaModel,

      rutas: [] as IRutaModel[]
    }
  },

  computed: {
    formRecord() {
      return this.$refs.formRecord as Vue & { validate: () => boolean; reset: () => void }
    }
  },

  mounted() {
    this.load()
  },

  methods: {
    listaEspera(item: IRutaModel) {
      window.open(
        `https://wa.me/${encodeURIComponent(this.num)}?text=${encodeURIComponent(
          `Hola ECOcaminantes, me gustaría estar en la lista de espera de la RUTA *${item.Nombre}* para el día  ${item.FechaCompleta}, quedo atento si se habilita el cupo, gracias.`
        )}`,
        '_blank'
      )
    },

    informacionViajeGrupal(item: IRutaModel) {
      window.open(
        `https://wa.me/${encodeURIComponent(this.num)}?text=${encodeURIComponent(`Hola ECOcaminantes, me gustaria obtener mas información sobre el Viaje grupal *${item.Nombre}*, gracias.`)}`,
        '_blank'
      )
    },

    calcularCupos(item: IRutaModel) {
      if (!item.Reservas) item.Reservas = []

      const Pendiente = item.Reservas.filter((f) => f.Estado === 'Pendiente').length || 0
      const Confirmada = item.Reservas.filter((f) => f.Estado === 'Confirmada').length || 0

      item.CuposPendientes = Pendiente
      item.CuposConfirmados = Confirmada
      item.CuposDisponibles = item.CuposOfertar - Confirmada

      return item
    },

    getLocation() {
      return location.origin + '/#/'
    },

    formatPrice(value: number) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return (
        '$ ' +
        val
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
          .replace(',00', '')
      )
    },

    async getRutas() {
      this.rutas = (await RutaService.findAll()) as any
    },

    async load() {
      if (this.$route.params.id) {
        this.$store.dispatch('app/showLoading')
        const response = (await RutaService.findById({ Id: this.$route.params.id } as any)) as any
        this.ruta = response
        this.help.title = response.Nombre
        this.help.message = ''
        this.getRutas()
        this.$store.dispatch('app/hideLoading')
      } else {
        this.$router.push('/experiencias')
      }
    },

    cancel() {
      this.$router.push('/experiencias')
    }
  }
})
