import { db, ServerValue, auth } from '@/firebase/db'
import { IUsuarioModel } from '@/store/types'

export default {
  findById: (data: IUsuarioModel): Promise<IUsuarioModel> =>
    new Promise((resolve) => {
      db.ref(`usuarios/${data.Id}`).once('value', (snapshot: any) => {
        resolve({ ...snapshot.val(), key: data.Id, Id: data.Id } as IUsuarioModel)
      })
    }),
  findAll: (): Promise<IUsuarioModel[]> =>
    new Promise((resolve) => {
      db.ref(`usuarios`)
        .orderByChild('createdAt')
        .on('value', (snapshot: any) => {
          let _snapshot: any = []
          if (snapshot.val()) {
            _snapshot = Object.entries(snapshot.val()).map((e) => Object.assign(e[1], { key: e[0], Id: e[0] }))
          }
          resolve((_snapshot as unknown) as IUsuarioModel[])
        })
    }),
  save: (data: any): Promise<any> =>
    new Promise((resolve) => {
      if (data['key']) {
        db.ref(`usuarios/${data['key']}`).set({ ...data, updatedAt: ServerValue }, (snapshot: any) => {
          resolve(snapshot)
        })
      } else {
        auth
          .createUserWithEmailAndPassword(data.Email, data.Clave)
          .then((response: any) => {
            response.user.sendEmailVerification()
            console.log(response.user)
            db.ref(`usuarios`).push({ ...data, createdAt: ServerValue }, (snapshot: any) => {
              resolve(snapshot)
            })
          })
          .catch((error: any) => {
            console.log(error)
          })
      }
    })
}
