








import Vue from 'vue'
import { mapGetters } from 'vuex'

import SidebarItem from './SidebarItem.vue'

export default Vue.extend({
  name: 'Sidebar',

  data() {
    return {
      dialog: false,
      items: [
        { icon: 'dashboard', text: 'Dashboard', path: '/admin' },
        {
          icon: 'mdi-folder',
          text: 'Logística de rutas',
          model: false,
          path: '/admin/rutas/mis-rutas'
        },
        {
          icon: 'redeem',
          text: 'Cupones',
          model: false,
          path: '/admin/configuracion/cupones'
        },
        {
          icon: 'military_tech',
          text: 'Premios de rutas',
          model: false,
          path: '/admin/rutas/premios'
        },
        {
          icon: 'calculate',
          text: 'Informe contable',
          model: false,
          path: '/admin/informes/rutas'
        },
        {
          icon: 'collections',
          text: 'Imagenes Banner',
          model: false,
          path: '/admin/configuracion/imagenes-banner'
        },
        {
          icon: 'supervised_user_circle',
          text: 'Creación de usuarios',
          model: false,
          path: '/admin/seguridad/usuarios'
        }
      ]
      // items: [
      //   { icon: 'dashboard', text: 'Dashboard', path: '/admin' },
      //   {
      //     icon: 'mdi-chevron-up',
      //     'icon-alt': 'folder_shared',
      //     text: 'Logística de Rutas',
      //     model: false,
      //     path: '/admin/rutas',
      //     children: [{ icon: 'mdi-folder', text: 'Listado de experiencias', path: 'mis-rutas' }]
      //   },
      //   {
      //     icon: 'mdi-chevron-up',
      //     'icon-alt': 'security',
      //     text: 'Seguridad',
      //     model: false,
      //     path: '/admin/seguridad',
      //     children: [{ icon: 'supervised_user_circle', text: 'Creación de usuario', path: 'usuarios' }]
      //   }
      // ]
    }
  },

  computed: {
    ...mapGetters({ drawer: 'app/drawer' })
  },

  components: {
    SidebarItem
  }
})
