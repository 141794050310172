import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import { AppModule } from './modules/app'
import { AuthModule } from './modules/auth'
import { IRootState } from './types'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence<IRootState>({
  storage: window.localStorage
})

export default new Vuex.Store<IRootState>({
  state: {
    loading: false
  },
  modules: {
    app: AppModule,
    auth: AuthModule
  },
  plugins: [vuexLocal.plugin]
})
