export interface IRootState {
  loading: boolean
}

export interface IUserModel {
  username: string
  password: string
  vm: any
}

export interface IAlertaModel {
  ALERTA_HORA_REQUISITO_VENCIDO: string
  ALERTA_PERIODICIDAD_INFORME_GERENCIAL: string
  ALERTA_HORA_INFORME_GERENCIAL: string
}

export interface IParametroModel {
  Id: number
  Nombre: string
  Valor: string
  Orden: number
  Activo: boolean
  UsuarioCreacionId: number
  UsuarioCreacion: string
  FechaCreacion: string
  UsuarioModificacionId: number
  UsuarioModificacion?: string
  FechaModificacion?: string
}

export interface IChartModel {
  label: string
  valor: number
}

export interface IOpcionMenuModel {
  Id: number
  Nombre: string
  Ruta: string
  Icono: string
  Activo: boolean
  UsuarioCreacionId: number
  FechaCreacion: string
  UsuarioModificacionId: number
  FechaModificacion?: string
}

export interface IUsuarioModel {
  Id: number
  NombreUsuario: string
  NombreCompleto: string
  Email: string
  Clave: string
  LoginLocal: boolean
  Rol: IRolModel
  RolId: number
  Activo: boolean
  UsuarioCreacionId: number
  UsuarioCreacion: IUsuarioModel
  FechaCreacion: string
  UsuarioModificacionId: number
  UsuarioModificacion?: IUsuarioModel
  FechaModificacion?: string
}

export interface IRolModel {
  Id: number
  Nombre: string
  Descripcion: string
  ControlTotal: boolean
  IndicadorEditable: boolean
  Activo: boolean
  RolOpcionMenu: IRolOpcionMenuModel[]
  UsuarioCreacionId: number
  UsuarioCreacion: IUsuarioModel
  FechaCreacion: string
  UsuarioModificacionId: number
  UsuarioModificacion?: IUsuarioModel
  FechaModificacion?: string
}

export interface IRolOpcionMenuModel {
  Id: number
  OpcionMenuId: number
  RolId: number
  UsuarioCreacionId: number
  UsuarioCreacion: IUsuarioModel
  FechaCreacion: string
}

export interface IRutaHorarioModel {
  Id: number
  Nombre: string
  Horario: string
}

export interface IRutaGastoModel {
  Id: number
  Nombre: string
  Valor: number
}

export interface IRutaAlimentacionModel {
  Id: number
  Nombre: string
}

export interface IRutaGaleriaModel {
  Id: number
  Nombre: string
  Url: string
}

export interface IRutaResevaModel {
  key: string
  Id: number
  IdReservaPadre: number
  IdRuta: number
  RutaNombre: string
  RutaFecha: string
  Numero: string
  Genero: string
  Identificacion: string
  Nombre: string
  Apellido: string
  Celular: string
  Email: string
  Instagram: string
  MedioContacto: string
  FormaPago: string
  ModalidadPago: string
  CantidadCuotas: number
  Ciudad: string
  Edad: string
  ReferidoPor: string
  Alimentacion: string
  AlimentacionAlmuerzo: string
  AlimentacionCena: string
  SoportePago: string
  SoportePago2: string
  PuntoSalida: string
  CantidadCupo: number
  Puntos: number
  EstadoPuntos: string
  CantidadAcompanantes: number
  NotaCliente: string
  NotaInterna: string
  Valor: number
  ValorReal: number
  Checkbox: boolean
  PagoConfirmado: boolean | false
  Estado: string
  Invitados: IRutaResevaModel[]
  NombreMascota: string
  TipoTransporte: string
  DisponiblesTransporte: boolean
  CuposDisponiblesTransporte: number
  createdAt: string
  updatedAt?: string
  IndCupon: boolean | false
  Cupon: string
  ValorDescuento: number | 0
  PorcentajeDescuento: number | 0
}

export interface IPremioModel {
  Id: number
  Nombre: string
  Descripcion: string
  Puntos: number
  Secuencia: number
  Url: string
  Activo: boolean
  UsuarioCreacionId: number
  UsuarioCreacion: IUsuarioModel
  UsuarioModificacionId: number
  UsuarioModificacion?: IUsuarioModel
  createdAt: string
  updatedAt?: string
}

export interface ISolicitudPremioModel {
  Id: number
  Estado: string
  IdProducto: number
  Identificacion: string
  Solicitante: string
  Nombre: string
  Descripcion: string
  Puntos: number
  UsuarioCreacionId: number
  UsuarioModificacionId: number
  createdAt: string
  updatedAt?: string
}

export interface IRutaModel {
  key: string
  Selected: boolean
  Id: number
  Sede: string
  Tipo: string
  LogisticaTransporte: boolean
  Nombre: string
  Moneda: string
  DescripcionPago: string
  Fecha: string
  FechaFinal: string
  FechaCompleta: string
  TipoTranporte: string
  Estado: string
  Dificultad: string
  ValorTransporte: number
  ValorNeto: number
  ValorBruto: number
  ValorTotal: number
  CuposOfertar: number
  CuposPendientes: number
  CuposConfirmados: number
  CuposDisponibles: number
  CuposDisponiblesManual: number
  CantidadCuotas: number
  CuposDisponiblesReal: number
  PendientesSoporte: number
  Alimentaciones: IRutaAlimentacionModel[]
  AlimentacionesAlmuerzo: IRutaAlimentacionModel[]
  AlimentacionesCena: IRutaAlimentacionModel[]
  Horarios: IRutaHorarioModel[]
  Gastos: IRutaGastoModel[]
  HorarioRegreso: string
  Ubicacion: string
  Duracion: string
  Descripcion: string
  Incluye: string
  Recomendaciones: string
  Galeria: IRutaGaleriaModel[]
  GaleriaEvidencias: IRutaGaleriaModel[]
  Reservas: IRutaResevaModel[]
  Activo: boolean
  Destacada: boolean
  Hashtags: string
  UsuarioCreacionId: number
  UsuarioCreacion: IUsuarioModel
  UsuarioModificacionId: number
  UsuarioModificacion?: IUsuarioModel
  createdAt: string
  updatedAt?: string
  Meses: any[]
  isWeekday?: boolean
}

export class FileUpload {
  key!: string
  name!: string
  url!: string
  file: File

  constructor(file: File) {
    this.file = file
  }
}

export interface IGaleriaModel {
  Id: number
  Nombre: string
  Url: string
  Activo: boolean
}

export interface ICuponModel {
  Id: number
  Codigo: string
  Porcentaje: number
  Activo: boolean
}
