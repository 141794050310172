import { ActionContext } from 'vuex'
import { IAuthState } from './state'
import { IRootState, IUserModel } from '../../types'
import { auth } from '@/firebase/db'
import router from '../../../router'
import store from '../../../store'

export interface IAuthActions {
  login(context: ActionContext<IAuthState, IRootState>, data: IUserModel): void
  forgotPassword(context: ActionContext<IAuthState, IRootState>, data: any): void
  logout(context: ActionContext<IAuthState, IRootState>, notReload: boolean): void
}

export const AuthActions: IAuthActions = {
  async login({ commit }: ActionContext<IAuthState, IRootState>, { username, password }: IUserModel) {
    await auth
      .signInWithEmailAndPassword(username, password)
      .then((res: any) => {
        console.log(res)
        commit('CHANGE_IS_AUTHENTICATED', true)
        // commit('SET_MENU', res.Result.Menu)
        commit('SET_USERNAME', res.user.email)
        commit('SET_CURUSER_OBJECT', res.user.email)
        router.push('/admin')
      })
      .catch((error) => {
        store.dispatch('app/alertPrompt', {
          text: `${error.message}`,
          type: 'error'
        })
      })
  },
  async forgotPassword({ commit }: ActionContext<IAuthState, IRootState>, { email }: any) {
    await auth
      .sendPasswordResetEmail(email)
      .then((res: any) => {
        store.dispatch('app/alertPrompt', {
          text: `Proceso exitoso, en breve recibira un email para la activación de su cuenta.`,
          type: 'success'
        })
      })
      .catch((error) => {
        store.dispatch('app/alertPrompt', {
          text: `${error.message}`,
          type: 'error'
        })
      })
  },
  async logout({ commit }: ActionContext<IAuthState, IRootState>, notReload: boolean) {
    commit('CHANGE_IS_AUTHENTICATED', false)
    commit('SET_TOKEN', null)
    commit('SET_MENU', [])
    commit('SET_USERNAME', null)
    commit('SET_CURUSER_OBJECT', null)
    await auth.signOut()
    router.push('/login')
    if (!notReload) location.reload()
  }
}
