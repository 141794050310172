import { db, ServerValue } from '@/firebase/db'
import { IRolModel } from '@/store/types'

export default {
  findById: (data: IRolModel): Promise<any> =>
    new Promise((resolve) => {
      db.ref(`roles/${data.Id}`).once('value', (snapshot) => {
        resolve({ ...snapshot.val(), key: data.Id, Id: data.Id } as IRolModel)
      })
    }),
  findAll: (): Promise<any> =>
    new Promise((resolve) => {
      db.ref(`roles`)
        .orderByChild('createdAt')
        .once('value', (snapshot: any) => {
          let _snapshot: any = []
          if (snapshot.val()) {
            _snapshot = Object.entries(snapshot.val()).map((e) => Object.assign(e[1], { key: e[0], Id: e[0] }))
          }
          resolve((_snapshot as unknown) as IRolModel[])
        })
    }),
  save: (data: any): Promise<any> =>
    new Promise((resolve) => {
      if (data['key']) {
        db.ref(`roles/${data['key']}`).set({ ...data, updatedAt: ServerValue }, (snapshot: any) => {
          resolve(snapshot)
        })
      } else {
        db.ref(`roles`).push({ ...data, createdAt: ServerValue }, (snapshot: any) => {
          resolve(snapshot)
        })
      }
    })
}
