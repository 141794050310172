


































































































































import Vue from 'vue'
// import { db } from '@/firebase/db'
/**  Model **/
import { IUsuarioModel } from '@/store/types'

/**  Service **/
import UsuarioService from '@/services/UsuarioService'
import RolService from '@/services/RolService'

export default Vue.extend({
  data() {
    return {
      help: {
        title: 'Usuarios del sistema',
        subtitle: '',
        message: 'En esta página podrás encontrar todos los usuarios que han sido creados.'
      },

      form: {
        loading: true,
        dialog: false,
        valid: false,
        record: { Activo: true } as IUsuarioModel,
        rules: {
          required: [(v: any) => !!v || 'Este campo es requerido']
        }
      },

      roles: [],

      table: {
        loading: true,
        filters: {
          search: null,
          rol: null
        },
        headers: [
          { text: 'Nombre y apellidos', value: 'NombreCompleto', align: 'left' },
          { text: 'Email', value: 'Email', align: 'left' },
          { text: 'Activo', value: 'Activo', align: 'center' },
          { text: 'Creación', value: 'UsuarioCreacion', align: 'left' },
          { text: 'Ultima modificación', value: 'UsuarioModificacion', align: 'left' },
          { text: 'Acciones', align: 'center' }
        ],
        records: [],
        rowsPerPage: [5, 10, 20, 25, 30, { text: 'Todos', value: -1 }]
      }
    }
  },

  components: {},

  computed: {
    formRecord(): any {
      return this.$refs.formRecord as Vue & { validate: () => boolean; reset: () => void }
    }
  },

  mounted() {
    this.load()
  },

  methods: {
    async load() {
      this.$store.dispatch('app/showLoading')
      this.table.records = (await UsuarioService.findAll()) as any
      this.roles = (await RolService.findAll()) as any
      this.$store.dispatch('app/hideLoading')
    },

    add() {
      this.form.dialog = true
      this.form.record = { Activo: true } as IUsuarioModel
    },

    async edit(item: IUsuarioModel) {
      this.$store.dispatch('app/showLoading')
      this.form.record = await UsuarioService.findById(item)
      this.form.dialog = true
      this.$store.dispatch('app/hideLoading')
    },

    async save() {
      if (this.formRecord.validate()) {
        this.$store.dispatch('app/showLoading')
        await UsuarioService.save({ ...this.form.record } as IUsuarioModel)
        this.$store.dispatch('app/hideLoading')
        this.reset()
        this.load()
        this.$store.dispatch('app/alertPrompt', {
          text: 'Proceso realizado correctamente.',
          type: 'success'
        })
      }
    },

    reset() {
      this.form.dialog = false
      this.form.record = { Activo: true } as IUsuarioModel
    },

    async download() {
      // this.$store.dispatch('app/showLoading')
      // let response = (await ReporteService.exportarDatos({ Opcion: 'DESCARGA_USUARIO' })) as any
      // window.open(`${response.Data}`)
      // this.$store.dispatch('app/hideLoading')
    }
  }
})
