import Login from './Login.vue'

// Rutas
import InformeRutas from './Rutas/InformeRutas.vue'
import Rutas from './Rutas/Rutas.vue'
import Ruta from './Rutas/Ruta.vue'
import CatalogoRutas from './Rutas/CatalogoRutas.vue'
import CatalogoRuta from './Rutas/CatalogoRuta.vue'
import CatalogoRutaPDF from './Rutas/CatalogoRutaPDF.vue'
import ReservaRuta from './Rutas/ReservaRuta.vue'
import RutasAgencias from './Rutas/RutasAgencias.vue'

// About
import Home from './About/Home.vue'

// Configuracion
import ImagenesBanner from './Configuracion/ImagenesBanner.vue'
import Cupones from './Configuracion/Cupones.vue'

// Seguridad
import Roles from './Seguridad/Roles.vue'
import Usuarios from './Seguridad/Usuarios.vue'

// Reportes
export default {
  Login,
  Home,

  RutasAgencias,
  Ruta,
  Rutas,
  ReservaRuta,
  InformeRutas,
  CatalogoRutas,
  CatalogoRuta,
  CatalogoRutaPDF,
  // Configuracion
  ImagenesBanner,
  Cupones,

  // Seguridad
  Usuarios,
  Roles

  // Reportes
}
