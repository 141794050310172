


















import Vue from 'vue'

export default Vue.extend({
  props: ['width'],

  data() {
    return {}
  },

  components: {},

  // mounted() {},

  methods: {}
})
