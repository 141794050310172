import { db, ServerValue, auth, storage } from '@/firebase/db'
import { IRutaModel, FileUpload, IRutaResevaModel } from '@/store/types'
import store from '@/store'
import moment from 'moment'

export default {
  findById: (data: IRutaModel): Promise<IRutaModel> =>
    new Promise((resolve) => {
      db.ref(`rutas/${data.Id}`).once('value', (snapshot: any) => {
        const _snapshot: any = { ...snapshot.val(), key: data.Id, Id: data.Id }

        if (_snapshot['FechaFinal'] && _snapshot['Tipo'] === 'Viaje grupal') {
          _snapshot['FechaCompleta'] = moment(_snapshot['Fecha']).format('DD/MM/yyyy') + ' al ' + moment(_snapshot['FechaFinal']).format('DD/MM/yyyy')
        } else {
          _snapshot['FechaCompleta'] = moment(_snapshot['Fecha']).format('DD/MM/yyyy')
        }

        _snapshot['Selected'] = false
        if (_snapshot['Reservas']) {
          _snapshot['Reservas'] = Object.entries(_snapshot['Reservas']).map((e) => {
            return Object.assign(e[1], { key: (e[1] as any).key || e[0], Id: (e[1] as any).Numero })
          })
        } else {
          _snapshot['Reservas'] = []
        }

        if (!_snapshot['Moneda']) {
          _snapshot['Moneda'] = 'COP'
        }

        if (!_snapshot['Meses']) {
          _snapshot['Meses'] = []
        }

        resolve((_snapshot as unknown) as IRutaModel)

        // resolve({ ...snapshot.val(), key: data.Id, Id: data.Id } as IRutaModel)
      })
    }),

  findReservaById: (data: IRutaResevaModel): Promise<IRutaResevaModel> =>
    new Promise((resolve) => {
      db.ref(`rutas/${data.IdRuta}/Reservas/${data.key}`).once('value', (snapshot: any) => {
        const _snapshot: any = { ...snapshot.val() }
        resolve((_snapshot as unknown) as IRutaResevaModel)
      })
    }),

  findAll: (sort = true): Promise<IRutaModel[]> =>
    new Promise((resolve) => {
      db.ref(`rutas`)
        .orderByChild('createdAt')
        .on('value', (snapshot: any) => {
          let _snapshot: any = []
          if (snapshot.val()) {
            _snapshot = Object.entries(snapshot.val()).map((e) => Object.assign(e[1], { key: e[0], Id: e[0] }))

            _snapshot = _snapshot.map((row: any) => {
              if (row['FechaFinal'] && row['Tipo'] === 'Viaje grupal') {
                row['FechaCompleta'] = moment(row['Fecha']).format('DD/MM/yyyy') + ' al ' + moment(row['FechaFinal']).format('DD/MM/yyyy')
              } else {
                row['FechaCompleta'] = moment(row['Fecha']).format('DD/MM/yyyy')
              }

              if (row['Tipo'] !== 'Viaje grupal') {
                row['Meses'] = [parseInt(moment(row['Fecha']).format('MM'))]

                row['isWeekday'] = moment(row['Fecha']).day() % 6 !== 0
              } else {
                row['isWeekday'] = null
              }

              row['Selected'] = false
              if (row['Reservas']) {
                row['Reservas'] = Object.entries(row['Reservas']).map((e) => Object.assign(e[1], { key: (e[1] as any).key || e[0], Id: (e[1] as any).Numero }))
              } else {
                row['Reservas'] = []
              }

              if (!row['Moneda']) {
                row['Moneda'] = 'COP'
              }

              if (!row['Meses']) {
                row['Meses'] = []
              }

              return row
            })
          }

          let rutas = (_snapshot as unknown) as IRutaModel[]

          if (rutas && !sort) {
            rutas = rutas.sort((a, b) => {
              return (moment(b.Fecha).valueOf() - moment(a.Fecha).valueOf()) as any
            })
          } else if (rutas) {
            rutas = rutas.sort((a, b) => {
              return (new Date(a.Fecha).getTime() - new Date(b.Fecha).getTime()) as any
            })
          }

          resolve(rutas)
        })
    }),

  remove: (data: IRutaModel): Promise<any> => db.ref(`rutas/${data.Id}`).remove(),

  save: (data: any): Promise<any> =>
    new Promise((resolve) => {
      if (data['key']) {
        const updates = {} as any
        Object.keys(data).forEach((key) => {
          if (key !== 'Reservas') updates[key] = data[key]
        })

        db.ref(`rutas/${data['key']}`).update({ ...updates, updatedAt: ServerValue, UsuarioModificacion: store.getters['auth/username'] }, (snapshot: any) => {
          resolve(snapshot)
        })

        // db.ref(`rutas/${data['key']}`).update({ ...data, updatedAt: ServerValue, UsuarioModificacion: store.getters['auth/username'] }, (snapshot: any) => {
        //   resolve(snapshot)
        // })
      } else {
        db.ref(`rutas`).push({ ...data, createdAt: ServerValue, UsuarioCreacion: store.getters['auth/username'] }, (snapshot: any) => {
          resolve(snapshot)
        })
      }
    }),

  removeReserva: (data: IRutaResevaModel): Promise<any> => db.ref(`rutas/${data.IdRuta}/Reservas/${data.key}`).remove(),

  saveReserva: (data: any): Promise<any> =>
    new Promise((resolve) => {
      Object.keys(data).forEach((key) => {
        if (!data[key] && !Array.isArray(data[key])) data[key] = null
      })

      if (data['key']) {
        db.ref(`rutas/${data.IdRuta}/Reservas/${data.key}`).update({ ...data, updatedAt: ServerValue, UsuarioModificacion: store.getters['auth/username'] }, (snapshot: any) => {
          resolve(snapshot)
        })
      } else {
        db.ref(`rutas/${data.IdRuta}/Reservas/${data.key}`).set({ ...data, createdAt: ServerValue, UsuarioCreacion: store.getters['auth/username'] }, (snapshot: any) => {
          resolve(snapshot)
        })
      }
    }),

  fileUpload: (data: FileUpload, ruta: IRutaModel): Promise<any> =>
    new Promise((resolve, reject) => {
      const storageRef = storage.ref(`/Imagenes/Rutas/${ruta.Id}/${data.name}`).put(data.file)
      storageRef.on(
        `state_changed`,
        (snapshot: any) => {
          console.log((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
        },
        (error: any) => {
          console.log(error.message)
          reject(error)
        },
        () => {
          storageRef.snapshot.ref
            .getDownloadURL()
            .then((url: any) => {
              console.log(url)
              resolve(url)
            })
            .catch((error: any) => {
              console.log(error)
              reject(error)
            })
        }
      )
    }),

  fileUploadSoportePago: (data: FileUpload, ruta: IRutaModel, reserva: IRutaResevaModel): Promise<any> =>
    new Promise((resolve, reject) => {
      const storageRef = storage.ref(`/Imagenes/Rutas/${ruta.Id}/SoportesPago/${reserva.Numero}`).put(data.file)
      storageRef.on(
        `state_changed`,
        (snapshot: any) => {
          console.log((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
        },
        (error: any) => {
          console.log(error.message)
          reject(error)
        },
        () => {
          storageRef.snapshot.ref
            .getDownloadURL()
            .then((url: any) => {
              console.log(url)
              resolve(url)
            })
            .catch((error: any) => {
              console.log(error)
              reject(error)
            })
        }
      )
    })
}
