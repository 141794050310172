import Vue from 'vue'
import App from './App.vue'
// import "./registerServiceWorker";
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './globalComponents'

import VueSweetalert2 from 'vue-sweetalert2'
import moment from 'moment-timezone'
import VueSocialSharing from 'vue-social-sharing'
import VueCurrencyInput from 'vue-currency-input'
import VueClipboard from 'vue-clipboard2'
import GoogleTranslatePlugin from './plugins/googleTranslate'

Vue.use(VueClipboard)
// import JsonExcel from 'vue-json-excel'
// import wysiwyg from "vue-wysiwyg";

/* eslint-disable @typescript-eslint/no-var-requires */
// const JsonExcel = require('vue-json-excel')

/* eslint-disable @typescript-eslint/no-var-requires */
// const wysiwyg = require('vue-wysiwyg')

/* eslint-disable @typescript-eslint/no-var-requires */
const VueEditor = require('vue2-editor')
// import VueEditor from 'vue2-editor'
/* eslint-disable @typescript-eslint/no-var-requires */
// const InstagramEmbed = require('vue-instagram-embed')

// import Embed from 'v-video-embed'

/* eslint-disable @typescript-eslint/no-var-requires */
const VueMoment = require('vue-moment')
/* eslint-disable @typescript-eslint/no-var-requires */
const Vuelidate = require('vuelidate')

import { rtdbPlugin } from 'vuefire'

// Vue.use(wysiwyg, {})
// Vue.use(JsonExcel)
// Vue.component('downloadExcel', JsonExcel)
// Vue.use(Embed)
// Vue.use(InstagramEmbed)

Vue.use(GoogleTranslatePlugin)

Vue.use(VueCurrencyInput)
Vue.use(VueSocialSharing)
Vue.use(rtdbPlugin)
Vue.use(VueSweetalert2)
Vue.use(Vuelidate)
Vue.use(VueMoment, {
  moment
})
Vue.use(VueEditor)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  mounted() {
    this.$loadGoogleTranslate()
  }
}).$mount('#app')
