import { db, ServerValue, auth, storage } from '@/firebase/db'
import { IGaleriaModel, FileUpload } from '@/store/types'
import store from '@/store'

export default {
  findAll: (): Promise<IGaleriaModel[]> =>
    new Promise((resolve) => {
      db.ref(`banners`)
        .orderByChild('createdAt')
        .on('value', (snapshot: any) => {
          let _snapshot: any = []
          if (snapshot.val()) {
            _snapshot = Object.entries(snapshot.val()).map((e) => Object.assign(e[1], { key: e[0], Id: e[0] }))
          }
          resolve((_snapshot as unknown) as IGaleriaModel[])
        })
    }),

  remove: (data: IGaleriaModel): Promise<any> => db.ref(`banners/${data.Id}`).remove(),

  save: (data: any): Promise<any> =>
    new Promise((resolve) => {
      if (data['key']) {
        db.ref(`banners/${data['key']}`).set({ ...data, updatedAt: ServerValue, UsuarioModificacion: store.getters['auth/username'] }, (snapshot: any) => {
          resolve(snapshot)
        })
      } else {
        db.ref(`banners`).push({ ...data, createdAt: ServerValue, UsuarioCreacion: store.getters['auth/username'] }, (snapshot: any) => {
          resolve(snapshot)
        })
      }
    }),

  fileUpload: (data: FileUpload): Promise<any> =>
    new Promise((resolve, reject) => {
      const storageRef = storage.ref(`/Imagenes/Banner/${data.name}`).put(data.file)
      storageRef.on(
        `state_changed`,
        (snapshot: any) => {
          console.log((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
        },
        (error: any) => {
          console.log(error.message)
          reject(error)
        },
        () => {
          storageRef.snapshot.ref
            .getDownloadURL()
            .then((url: any) => {
              console.log(url)
              resolve(url)
            })
            .catch((error: any) => {
              console.log(error)
              reject(error)
            })
        }
      )
    })
}
