













































































import Vue from 'vue'
// import { db } from '@/firebase/db'
/**  Model **/
import { IGaleriaModel, FileUpload } from '@/store/types'

/**  Service **/
import GaleriaService from '@/services/GaleriaService'

export default Vue.extend({
  data() {
    return {
      help: {
        title: 'Imagenes para banner',
        subtitle: '',
        message: 'En esta página podrás encontrar todas las imagenes para banner que han sido creadas.'
      },

      form: {
        loading: true,
        dialog: false,
        valid: false,
        record: { Activo: true } as IGaleriaModel,
        rules: {
          required: [(v: any) => !!v || 'Este campo es requerido']
        }
      },

      roles: [],

      table: {
        loading: true,
        filters: {
          search: null,
          rol: null
        },
        headers: [
          // { text: 'Nombre', value: 'Nombre', align: 'left' },
          { text: 'Link', value: 'Url', align: 'left' },
          { text: 'Url', value: 'Url', align: 'left' },
          // { text: 'Activo', value: 'Activo', align: 'center' },
          { text: 'Creación', value: 'UsuarioCreacion', align: 'left' },
          { text: 'Ultima modificación', value: 'UsuarioModificacion', align: 'left' },
          { text: 'Acciones', align: 'center' }
        ],
        records: [],
        rowsPerPage: [5, 10, 20, 25, 30, { text: 'Todos', value: -1 }]
      }
    }
  },

  computed: {
    formRecord(): any {
      return this.$refs.formRecord as Vue & { validate: () => boolean; reset: () => void }
    }
  },

  mounted() {
    this.load()
  },

  methods: {
    async load() {
      this.$store.dispatch('app/showLoading')
      this.table.records = (await GaleriaService.findAll()) as any
      this.$store.dispatch('app/hideLoading')
    },

    async eliminarGaleria(item: any, index: number) {
      const _confirm = await this.$store.dispatch('app/confirm', `Confirme para continuar`)

      if (_confirm !== 'OK') return

      await GaleriaService.remove(item)
      await this.load()
    },

    async recibirArchivo(file: any) {
      if (file) {
        let fileU = new FileUpload(file)
        fileU.name = file.name
        this.$store.dispatch('app/showLoading')
        const response = (await GaleriaService.fileUpload(fileU)) as any
        await GaleriaService.save({ Url: response })
        await this.load()
        this.$store.dispatch('app/hideLoading')
      }
    }
  }
})
