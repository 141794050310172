






















































import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'

/**  Service **/

/**  Views **/
import Sidebar from './Sidebar.vue'

export default Vue.extend({
  data: () => ({}),

  computed: {
    ...mapGetters({ username: 'auth/username', token: 'auth/token', loading: 'app/loading', help: 'app/help', drawer: 'app/drawer' })
  },

  methods: {
    ...mapActions({ login: 'auth/login', logout: 'auth/logout', toggleSideBar: 'app/toggleSideBar', toggleHelp: 'app/toggleHelp' })
  },

  created() {
    this.$store.dispatch('app/hideLoading')
  },

  components: {
    Sidebar
  }
})
