import { Module } from 'vuex'
import { AuthDefaultState, IAuthState } from './state'
import { AuthActions } from './actions'
import { AuthGetters } from './getters'
import { AuthMutations } from './mutations'
import { IRootState } from '../../types'

export const AuthModule: Module<IAuthState, IRootState> = {
  namespaced: true,
  actions: {
    ...AuthActions
  },
  getters: {
    ...AuthGetters
  },
  state: {
    ...AuthDefaultState()
  },
  mutations: {
    ...AuthMutations
  }
}
