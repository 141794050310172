





































import Vue from 'vue'
import { mapGetters } from 'vuex'
/* eslint-disable @typescript-eslint/no-var-requires */
const path = require('path')

export default Vue.extend({
  name: 'SidebarItem',

  props: ['item', 'basePath'],

  data() {
    return {}
  },

  computed: {
    ...mapGetters({ menu: 'auth/menu' })
  },

  methods: {
    resolvePath(routePath: string) {
      return path.resolve(this.basePath, routePath)
    },

    showOption(elementPath: string) {
      return true
      // if (this.menu.find((f: any) => f.startsWith('/' + elementPath + '/'))) {
      //   return true
      // } else {
      //   return false
      // }
    },

    showOptionChildren(elementPath: string, childrenPath: string) {
      return true
      // if (this.menu.indexOf('/' + elementPath + '/' + childrenPath) !== -1) {
      //   return true
      // } else {
      //   return false
      // }
    }
  }
})
