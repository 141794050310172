import Vue, { PluginObject } from 'vue'

declare global {
  interface Window {
    googleTranslateElementInit: () => void
    google: any
  }
}

const GoogleTranslatePlugin: PluginObject<void> = {
  install(Vue) {
    Vue.prototype.$loadGoogleTranslate = () => {
      const script = document.createElement('script')
      script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
      script.async = true
      document.head.appendChild(script)

      window.googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: 'es', // Set your default language
            includedLanguages: 'en,es,fr,de,zh-CN', // List of languages to include
            layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE
          },
          'google_translate_element'
        )
      }
    }
  }
}

export default GoogleTranslatePlugin
