


































import Vue from 'vue'

export default Vue.extend({
  props: [
    'breadcrumbItems',
    'heading',
    'colClasses',
    'customClasses',
    'fullBlock',
    'contentCustomClass',
    'closeable',
    'reloadable',
    'fullScreen',
    'footer',
    'withTabs',
    'tabs',
    'onChangeTabCallback'
  ]
})
