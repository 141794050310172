






































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue'
// import moment from 'moment'

/**  Model **/
import { IRutaModel, IRutaHorarioModel, IRutaAlimentacionModel, IRutaGaleriaModel, FileUpload, IRutaResevaModel, IRutaGastoModel } from '@/store/types'

/**  Service **/
import { v4 as uuidv4 } from 'uuid'
import RutaService from '@/services/RutaService'

/**  Views **/
import FormularioRuta from '@/views/Rutas/FormularioRuta.vue'
import { CurrencyDirective } from 'vue-currency-input'

export default Vue.extend({
  components: {
    FormularioRuta
  },

  data() {
    return {
      help: {
        title: 'Registrar Experiencia',
        subtitle: '',
        message: 'En esta página podrás registrar tus experiencias.'
      },

      tab: 0,

      headerHorarios: [
        {
          text: 'Nombre',
          value: 'Nombre',
          align: 'center',
          sortable: false
        },
        {
          text: 'Horario',
          value: 'Horario',
          align: 'center',
          sortable: false
        },
        {
          text: 'Acciones',
          value: '',
          align: 'center',
          sortable: false
        }
      ],

      headerGastos: [
        {
          text: 'Nombre',
          value: 'Nombre',
          align: 'center',
          sortable: false
        },
        {
          text: 'Valor',
          value: 'Valor',
          align: 'center',
          sortable: false
        },
        {
          text: 'Acciones',
          value: '',
          align: 'center',
          sortable: false
        }
      ],

      headerAlimentaciones: [
        {
          text: 'Nombre',
          value: 'Nombre',
          align: 'center',
          sortable: false
        },
        {
          text: 'Acciones',
          value: '',
          align: 'center',
          sortable: false
        }
      ],

      headerGaleria: [
        {
          text: 'Nombre',
          value: 'Nombre',
          align: 'center',
          sortable: false
        },
        {
          text: 'Enlace',
          value: 'Url',
          align: 'center',
          sortable: false
        },
        {
          text: 'Preview',
          align: 'center',
          sortable: false
        },
        {
          text: 'Acciones',
          value: '',
          align: 'center',
          sortable: false
        }
      ],

      formasPago: [
        // {
        //   Text: 'Daviplata',
        //   Value: 'Daviplata - 3167003712'
        // },
        {
          text: 'Nequi',
          value: 'Nequi - 3167003712'
        },
        {
          text: 'Bancolombia',
          value: 'Bancolombia - Cuenta de ahorro - 91213156824'
        }
      ],

      meses: [
        {
          text: 'Enero',
          value: 1
        },
        {
          text: 'Febrero',
          value: 2
        },
        {
          text: 'Marzo',
          value: 3
        },
        {
          text: 'Abril',
          value: 4
        },
        {
          text: 'Mayo',
          value: 5
        },
        {
          text: 'Junio',
          value: 6
        },
        {
          text: 'Julio',
          value: 7
        },
        {
          text: 'Agosto',
          value: 8
        },
        {
          text: 'Septiembre',
          value: 9
        },
        {
          text: 'Octubre',
          value: 10
        },
        {
          text: 'Noviembre',
          value: 11
        },
        {
          text: 'Diciembre',
          value: 12
        }
      ],

      modalidadesPago: [
        {
          Text: 'Al contado',
          Value: 'Al contado'
        },
        {
          Text: 'A cuotas',
          Value: 'A cuotas'
        }
      ],

      table: {
        loading: true,
        filters: {
          search: null,
          FechaDesde: '',
          FechaHasta: ''
        },
        headers: [
          { text: 'Numero', value: 'Numero', align: 'left' },
          { text: 'Reserva padre', value: 'IdReservaPadre', align: 'left' },
          { text: 'Estado', value: 'Estado', align: 'center' },
          { text: 'Soporte de pago', value: 'Nombre', align: 'left' },
          { text: 'Soporte de pago 2', value: 'Apellido', align: 'left' },
          { text: 'Cliente / Mascota', value: 'Identificacion', align: 'center' },
          { text: 'Contacto', value: 'Fecha', align: 'left' },
          { text: 'Alimentación', value: 'Alimentacion', align: 'left' },
          { text: 'Salida / Transporte', value: 'PuntoSalida', align: 'left' },
          { text: 'Cantidad cupo', value: 'CantidadCupo', align: 'left' },
          { text: 'Pago', value: 'FormaPago', align: 'left' },
          { text: 'Valor ruta', value: 'ValorReal', align: 'left' },
          { text: 'Valor pago recibido', value: 'Valor', align: 'left' },
          { text: 'Descuento', value: 'ValorDescuento', align: 'left' },
          { text: 'Nota cliente', value: 'NotaCliente', align: 'left' },
          { text: 'Nota interna', value: 'NotaInterna', align: 'left' },
          { text: 'Ultima modificación', value: 'UsuarioModificacion', align: 'left' },
          { text: 'Acciones', align: 'center', width: '5%' }
        ],
        records: [] as IRutaModel[],
        rowsPerPage: [5, 10, 20, 25, 30, { text: 'Todos', value: -1 }]
      },

      form: {
        loading: true,
        dialog: false,
        valid: false,
        reserva: {} as IRutaResevaModel,
        record: {
          Meses: [] as any[],
          Moneda: 'COP',
          CantidadCuotas: 1,
          ValorTransporte: 0,
          ValorNeto: 0,
          ValorBruto: 0,
          ValorTotal: 0,
          CuposOfertar: 0,
          CuposPendientes: 0,
          CuposConfirmados: 0,
          CuposDisponibles: 0,
          CuposDisponiblesManual: 0,
          CuposDisponiblesReal: 0,
          Reservas: [] as IRutaResevaModel[],
          Horarios: [] as IRutaHorarioModel[],
          Alimentaciones: [] as IRutaAlimentacionModel[],
          AlimentacionesAlmuerzo: [] as IRutaAlimentacionModel[],
          AlimentacionesCena: [] as IRutaAlimentacionModel[],
          Galeria: [] as IRutaGaleriaModel[],
          Gastos: [] as IRutaGastoModel[]
        } as IRutaModel,
        rules: {
          required: [(v: any) => !!v || 'Este campo es requerido']
        }
      }
    }
  },

  computed: {
    formRecord(): any {
      return this.$refs.formRecord as Vue & { validate: () => boolean; reset: () => void }
    },

    formReserva(): any {
      return this.$refs.formReserva as Vue & { validate: () => boolean; reset: () => void }
    },

    filterRecords(): any {
      return this.table.records.filter(
        (f: any) => (!this.table.filters.FechaDesde || f.Fecha >= this.table.filters.FechaDesde) && (!this.table.filters.FechaHasta || f.Fecha <= this.table.filters.FechaHasta)
      )
    }
  },

  directives: {
    currency: CurrencyDirective
  },

  mounted() {
    this.load()
  },

  methods: {
    totalGastos() {
      let total = 0
      this.form.record.Gastos.map((row) => {
        total += row.Valor
      })
      return this.formatPrice(total)
    },

    async anularReserva() {
      const _confirm = await this.$store.dispatch('app/confirm', `Confirme para continuar`)

      if (_confirm !== 'OK') return
    },

    guid() {
      let uud = uuidv4().toString()
      return uud.substring(0, 8)
    },

    async eliminarReserva(item: any, index: number) {
      const _confirm = await this.$store.dispatch('app/confirm', `Confirme para continuar`)

      if (_confirm !== 'OK') return

      await RutaService.removeReserva(item)

      this.$store
        .dispatch('app/alertPrompt', {
          text: 'Proceso realizado correctamente.',
          type: 'success'
        })
        .then(() => {
          location.reload()
        })

      // const _index = this.form.record.Reservas.indexOf(item)

      // this.form.record.Reservas.splice(_index, 1)
    },

    async modificarReserva(item: IRutaResevaModel) {
      this.form.reserva = item
      this.form.dialog = true
    },

    async agregarReserva() {
      this.form.dialog = true
    },

    formatPrice(value: number) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return '$ ' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },

    async recibirSoportePago(file: any, index: number, item: any) {
      if (file) {
        let fileU = new FileUpload(file)
        fileU.name = file.name
        this.$store.dispatch('app/showLoading')
        const response = (await RutaService.fileUploadSoportePago(fileU, this.form.record, item)) as any

        item.SoportePago = response as any

        await RutaService.saveReserva({ ...item, key: item.Numero, Id: item.Numero })

        location.reload()

        this.$store.dispatch('app/hideLoading')
      }
    },

    async recibirSoportePago2(file: any, index: number, item: any) {
      if (file) {
        let fileU = new FileUpload(file)
        fileU.name = file.name
        this.$store.dispatch('app/showLoading')
        const response = (await RutaService.fileUploadSoportePago(fileU, this.form.record, item)) as any

        item.SoportePago2 = response as any

        await RutaService.saveReserva({ ...item, key: item.Numero, Id: item.Numero })

        location.reload()

        this.$store.dispatch('app/hideLoading')
      }
    },

    async recibirArchivo(file: any) {
      if (file) {
        let fileU = new FileUpload(file)
        fileU.name = file.name
        this.$store.dispatch('app/showLoading')
        const response = (await RutaService.fileUpload(fileU, this.form.record)) as any
        this.form.record.Galeria.push({ Url: response } as IRutaGaleriaModel)
        this.$store.dispatch('app/hideLoading')
      }
    },

    agregarAlimentacion(tipo: string) {
      if (tipo === 'DESAYUNO') {
        this.form.record.Alimentaciones.push({} as IRutaAlimentacionModel)
      } else if (tipo === 'ALMUERZO') {
        this.form.record.AlimentacionesAlmuerzo.push({} as IRutaAlimentacionModel)
      } else if (tipo === 'CENA') {
        this.form.record.AlimentacionesCena.push({} as IRutaAlimentacionModel)
      }
    },

    agregarHorario() {
      this.form.record.Horarios.push({} as IRutaHorarioModel)
    },

    agregarGasto() {
      this.form.record.Gastos.push({} as IRutaGastoModel)
    },

    async eliminarAlimentacion(item: any, index: number, tipo: string) {
      const _confirm = await this.$store.dispatch('app/confirm', `Confirme para continuar`)

      if (_confirm !== 'OK') return

      if (tipo === 'DESAYUNO') {
        this.form.record.Alimentaciones.splice(index, 1)
      } else if (tipo === 'ALMUERZO') {
        this.form.record.AlimentacionesAlmuerzo.splice(index, 1)
      } else if (tipo === 'CENA') {
        this.form.record.AlimentacionesCena.splice(index, 1)
      }
    },

    async eliminarHorario(item: any, index: number) {
      const _confirm = await this.$store.dispatch('app/confirm', `Confirme para continuar`)

      if (_confirm !== 'OK') return

      this.form.record.Horarios.splice(index, 1)
    },

    async eliminarGasto(item: any, index: number) {
      const _confirm = await this.$store.dispatch('app/confirm', `Confirme para continuar`)

      if (_confirm !== 'OK') return

      this.form.record.Gastos.splice(index, 1)
    },

    async eliminarGaleria(item: any, index: number) {
      const _confirm = await this.$store.dispatch('app/confirm', `Confirme para continuar`)

      if (_confirm !== 'OK') return

      this.form.record.Galeria.splice(index, 1)
    },

    calcularCupos() {
      if (!this.form.record.Reservas) this.form.record.Reservas = []

      const Pendiente = this.form.record.Reservas.filter((f) => f.Estado === 'Pendiente').length || 0
      const Confirmada = this.form.record.Reservas.filter((f) => f.Estado === 'Confirmada').length || 0

      this.form.record.CuposPendientes = Pendiente
      this.form.record.CuposConfirmados = Confirmada
      this.form.record.CuposDisponibles = this.form.record.CuposOfertar - Confirmada
      this.form.record.CuposDisponiblesReal = this.form.record.CuposDisponibles - this.form.record.CuposDisponiblesManual
    },

    async load() {
      this.$store.dispatch('app/showLoading')
      if (this.$route.params.id) {
        const response = (await RutaService.findById({ Id: this.$route.params.id } as any)) as any

        if (!response.Reservas) response.Reservas = []

        this.form.record = response

        if (!this.form.record.Alimentaciones) this.$set(this.form.record, 'Alimentaciones', [])
        if (!this.form.record.AlimentacionesAlmuerzo) this.$set(this.form.record, 'AlimentacionesAlmuerzo', [])
        if (!this.form.record.AlimentacionesCena) this.$set(this.form.record, 'AlimentacionesCena', [])
        if (!this.form.record.Horarios) this.$set(this.form.record, 'Horarios', [])
        if (!this.form.record.Galeria) this.$set(this.form.record, 'Galeria', [])
        if (!this.form.record.Reservas) this.$set(this.form.record, 'Reservas', [])
        if (!this.form.record.Gastos) this.$set(this.form.record, 'Gastos', [])

        this.calcularCupos()
        this.help.title = this.form.record.Nombre

        if (this.form.record.Reservas.length > 0) this.tab = 2
      }
      this.$store.dispatch('app/hideLoading')
    },

    async save() {
      if (this.formRecord.validate()) {
        this.$store.dispatch('app/showLoading')
        var ValorTotal = parseFloat(this.form.record.ValorTransporte?.toString() || '0') + parseFloat(this.form.record.ValorNeto?.toString() || '0')
        let model = {
          ...this.form.record,
          ValorTotal: ValorTotal
        } as any

        delete model.Reservas

        await RutaService.save(model as IRutaModel)
        this.$store.dispatch('app/hideLoading')
        this.$store.dispatch('app/alertPrompt', {
          text: 'Proceso realizado correctamente.',
          type: 'success'
        })
        this.$router.push(`/admin/rutas/mis-rutas`)
      }
    },

    reset() {
      this.$router.push('/admin/rutas/mis-rutas')
    },

    async download(item: any) {
      /* eslint-disable @typescript-eslint/no-var-requires */
      const ExcelJS = require('exceljs')

      /* eslint-disable @typescript-eslint/no-var-requires */
      const FileSaver = require('file-saver')

      const workbook = new ExcelJS.Workbook()
      workbook.creator = 'LAB'
      workbook.lastModifiedBy = 'LAB'
      workbook.created = new Date()
      workbook.modified = new Date()
      workbook.lastPrinted = new Date()

      const worksheet = workbook.addWorksheet(item.Nombre)

      const fields = [
        { text: 'Numero', value: 'Numero' },
        { text: 'Id Reserva Padre', value: 'IdReservaPadre' },
        { text: 'Identificacion', value: 'Identificacion' },
        { text: 'Nombre', value: 'Nombre' },
        { text: 'Apellido', value: 'Apellido' },
        { text: 'Celular', value: 'Celular' },
        { text: 'Instagram', value: 'Instagram' },
        { text: 'Email', value: 'Email' },
        { text: 'Ciudad', value: 'Ciudad' },
        { text: 'Edad', value: 'Edad' },
        { text: 'Forma de Pago', value: 'FormaPago' },
        { text: 'Modalidad de Pago', value: 'ModalidadPago' },
        { text: 'Cantidad de Cuotas', value: 'CantidadCuotas' },
        { text: 'Alimentacion', value: 'Alimentacion' },
        { text: 'Alimentacion de Almuerzo', value: 'AlimentacionAlmuerzo' },
        { text: 'Alimentacion de Cena', value: 'AlimentacionCena' },
        { text: 'Punto de Salida', value: 'PuntoSalida' },
        { text: 'Cantidad de Cupos reserva', value: 'CantidadCupo' },
        { text: 'Cantidad de Acompanantes reserva', value: 'CantidadAcompanantes' },
        { text: 'Soporte de Pago', value: 'SoportePago' },
        { text: 'Soporte de Pago 2', value: 'SoportePago2' },
        { text: 'Valor ruta', value: 'ValorReal' },
        { text: 'Valor pago recibido', value: 'Valor' },
        { text: 'Valor descuento', value: 'ValorDescuento' },
        { text: 'Porcentaje de descuento', value: 'PorcentajeDescuento' },
        { text: 'Código de descuento', value: 'Cupon' },
        { text: 'Nombre de la Mascota', value: 'NombreMascota' },
        { text: 'Tipo de Transporte', value: 'TipoTransporte' },
        // { text: '¿Puedes llevar a alguien?', value: 'DisponiblesTransporte' },
        { text: '¿Cupos disponibles para transportar otros viajeros?', value: 'CuposDisponiblesTransporte' },
        { text: 'Nota Cliente', value: 'NotaCliente' },
        { text: 'Nota Interna', value: 'NotaInterna' },
        { text: 'Estado', value: 'Estado' },
        { text: 'Pago Confirmado', value: 'PagoConfirmado' },
        { text: 'Medio de contacto', value: 'MedioContacto' }
      ]

      const rows = fields.map((row) => row.text)

      worksheet.addRow(rows)

      item.Reservas.forEach((row: any) => {
        let _row = [] as any
        fields.forEach((r: any) => {
          if (r.value === 'DisponiblesTransporte' || r.value === 'PagoConfirmado') {
            _row.push(row[r.value] === true ? 'SI' : 'NO')
          } else {
            _row.push(row[r.value])
          }
        })

        worksheet.addRow(_row)
      })

      const fileName = item.Nombre + '.xlsx'
      workbook.xlsx.writeBuffer().then((data: any) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        FileSaver.saveAs(blob, fileName)
      })
    }
  }
})
