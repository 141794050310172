


































































































































































































import Vue from 'vue'
import { v4 as uuidv4 } from 'uuid'

/**  Model **/
import { IRutaModel } from '@/store/types'

/**  Service **/
import RutaService from '@/services/RutaService'

export default Vue.extend({
  data() {
    return {
      help: {
        title: 'Listado de experiencias',
        subtitle: '',
        message: 'En esta página podrás encontrar todas las experiencias radicadas en el sistema.'
      },

      form: {
        loading: true,
        dialog: false,
        valid: false,
        record: {},
        rules: {
          required: [(v: any) => !!v || 'Este campo es requerido']
        }
      },

      table: {
        loading: true,
        filters: {
          search: null,
          Sede: null,
          Estado: null,
          FechaDesde: '',
          FechaHasta: '',
          Tipo: null
        },
        headers: [
          { text: 'Sede', value: 'Sede', align: 'left', width: '15%' },
          { text: 'Nombre', value: 'Nombre', align: 'left', width: '15%' },
          { text: 'Fecha de la ruta', value: 'Fecha', align: 'left', width: '15%' },
          { text: 'Dificultad', value: 'Dificultad', align: 'left', width: '15%' },
          { text: 'Cupos ofertardos', value: 'CuposOfertar', align: 'left', width: '10%' },
          { text: 'Cupos pendientes', value: 'CuposPendientes', align: 'left', width: '10%' },
          { text: 'Cupos confirmados', value: 'CuposConfirmados', align: 'left', width: '10%' },
          { text: 'Cupos disponibles', value: 'CuposDisponibles', align: 'left', width: '10%' },
          { text: 'Confirmados sin soporte de pago', value: 'PendientesSoporte', align: 'left', width: '10%' },
          { text: 'Estado', value: 'Estado', align: 'center', width: '10%' },
          { text: 'Creación', value: 'UsuarioCreacion', align: 'left', width: '20%' },
          { text: 'Ultima modificación', value: 'UsuarioModificacion', align: 'left', width: '20%' },
          { text: 'Acciones', align: 'center', width: '5%' }
        ],
        records: [] as IRutaModel[],
        rowsPerPage: [5, 10, 20, 25, 30, { text: 'Todos', value: -1 }]
      }
    }
  },

  components: {},

  computed: {
    filterRecords(): any {
      return this.table.records.filter(
        (f: any) =>
          (!this.table.filters.Estado || f.Estado === this.table.filters.Estado) &&
          (!this.table.filters.Sede || f.Sede === this.table.filters.Sede) &&
          (!this.table.filters.Tipo || f.Tipo === this.table.filters.Tipo) &&
          (!this.table.filters.FechaDesde || f.Fecha >= this.table.filters.FechaDesde) &&
          (!this.table.filters.FechaHasta || f.Fecha <= this.table.filters.FechaHasta)
      )
    }
  },

  mounted() {
    this.load()
  },

  methods: {
    guid() {
      let uud = uuidv4().toString()
      return uud.substring(0, 8)
    },

    async reservarCupo(ruta: any) {
      let model = {
        key: ruta.Id,
        IdRuta: ruta.Id,
        Estado: 'Confirmada',
        Numero: this.guid(),
        Valor: ruta.ValorTotal,
        ValorReal: ruta.ValorTotal
      }

      await RutaService.saveReserva({ ...model })

      this.$store.dispatch('app/alertPrompt', {
        text: `Número reserva: ${model.Numero}, Proceso realizado correctamente.`,
        type: 'success'
      })
    },

    calcularCupos(item: IRutaModel) {
      if (!item.Reservas) item.Reservas = []

      const Pendiente = item.Reservas.filter((f) => f.Estado === 'Pendiente').length || 0
      const Confirmada = item.Reservas.filter((f) => f.Estado === 'Confirmada').length || 0

      item.CuposPendientes = Pendiente
      item.CuposConfirmados = Confirmada
      item.CuposDisponibles = item.CuposOfertar - Confirmada

      item.PendientesSoporte = item.CuposConfirmados - item.Reservas.filter((f) => f.Estado === 'Confirmada' && f.SoportePago).length || 0
      return item
    },

    formatPrice(value: number) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },

    async load() {
      this.$store.dispatch('app/showLoading')
      let records = (await RutaService.findAll(false)) as any
      records = records.map((row: IRutaModel) => this.calcularCupos(row))
      this.table.records = records
      this.$store.dispatch('app/hideLoading')
    },

    add() {
      this.$router.push({ path: '/admin/rutas/ruta' })
    },

    edit(item: any) {
      this.$router.push({ path: '/admin/rutas/ruta/' + item.Id })
      // const routeData = this.$router.resolve({ path: '/admin/rutas/ruta/' + item.Id })
      // window.open(routeData.href)
    },

    async duplicate(item: any) {
      const _confirm = await this.$store.dispatch('app/confirm', `Confirme para continuar`)

      if (_confirm !== 'OK') return

      this.$store.dispatch('app/showLoading')
      await RutaService.save({
        ...item,
        key: null,
        Id: null,
        CuposPendientes: 0,
        CuposConfirmados: 0,
        CuposDisponibles: item.CuposOfertar,
        Nombre: 'Copia de ' + item.Nombre,
        Reservas: []
      } as IRutaModel)

      this.$store.dispatch('app/hideLoading')
      this.$store.dispatch('app/alertPrompt', {
        text: 'Proceso realizado correctamente.',
        type: 'success'
      })
      this.load()
    },

    pdf(item: any) {
      const routeData = this.$router.resolve({ path: `/pdf/${item.Id}` })
      window.open(location.origin + '/' + routeData.href)
    },

    copyRutasDispnobles() {
      let text = ''

      this.table.records
        .filter((f: any) => f.Estado === 'Disponible')
        .forEach((item) => {
          const routeData = this.$router.resolve({ path: `/reserva/ruta/${item.Id}/XTZ` })
          const url = location.origin + '/' + routeData.href
          text += `*${item.Fecha} - ${item.Nombre}*\r\n${url}\r\n\r\n`
        })

      this.$copyText(text).then(
        () => {
          this.$store.dispatch('app/alertPrompt', {
            text: 'Enlaces copiados.',
            type: 'success'
          })
        },
        () => {
          this.$store.dispatch('app/alertPrompt', {
            text: 'No puede copiar.',
            type: 'error'
          })
        }
      )
    },

    copy(item: any, confirm: string) {
      const routeData = this.$router.resolve({ path: `/reserva/ruta/${item.Id}/${confirm}` })
      this.$copyText(location.origin + '/' + routeData.href).then(
        () => {
          this.$store.dispatch('app/alertPrompt', {
            text: 'Enlace copiado.',
            type: 'success'
          })
        },
        () => {
          this.$store.dispatch('app/alertPrompt', {
            text: 'No puede copiar.',
            type: 'error'
          })
        }
      )
    },

    async remove(item: any) {
      const _confirm = await this.$store.dispatch('app/confirm', `Confirme para continuar`)

      if (_confirm !== 'OK') return

      this.$store.dispatch('app/showLoading')
      await RutaService.remove(item)
      this.load()
    },

    async download(item: any) {
      /* eslint-disable @typescript-eslint/no-var-requires */
      const ExcelJS = require('exceljs')

      /* eslint-disable @typescript-eslint/no-var-requires */
      const FileSaver = require('file-saver')

      const workbook = new ExcelJS.Workbook()
      workbook.creator = 'LAB'
      workbook.lastModifiedBy = 'LAB'
      workbook.created = new Date()
      workbook.modified = new Date()
      workbook.lastPrinted = new Date()

      const worksheet = workbook.addWorksheet(item.Nombre)

      const fields = [
        { text: 'Numero', value: 'Numero' },
        { text: 'Id Reserva Padre', value: 'IdReservaPadre' },
        { text: 'Identificacion', value: 'Identificacion' },
        { text: 'Nombre', value: 'Nombre' },
        { text: 'Apellido', value: 'Apellido' },
        { text: 'Celular', value: 'Celular' },
        { text: 'Instagram', value: 'Instagram' },
        { text: 'Ciudad', value: 'Ciudad' },
        { text: 'Email', value: 'Email' },
        { text: 'Edad', value: 'Edad' },
        { text: 'Forma de Pago', value: 'FormaPago' },
        { text: 'Modalidad de Pago', value: 'ModalidadPago' },
        { text: 'Cantidad de Cuotas', value: 'CantidadCuotas' },
        { text: 'Alimentacion', value: 'Alimentacion' },
        { text: 'Alimentacion de Almuerzo', value: 'AlimentacionAlmuerzo' },
        { text: 'Alimentacion de Cena', value: 'AlimentacionCena' },
        { text: 'Punto de Salida', value: 'PuntoSalida' },
        { text: 'Cantidad de Cupos reserva', value: 'CantidadCupo' },
        { text: 'Cantidad de Acompanantes reserva', value: 'CantidadAcompanantes' },
        { text: 'Soporte de Pago', value: 'SoportePago' },
        { text: 'Valor ruta', value: 'ValorReal' },
        { text: 'Valor pago recibido', value: 'Valor' },
        { text: 'Valor descuento', value: 'ValorDescuento' },
        { text: 'Porcentaje de descuento', value: 'PorcentajeDescuento' },
        { text: 'Código de descuento', value: 'Cupon' },
        { text: 'Nombre de la Mascota', value: 'NombreMascota' },
        { text: 'Tipo de Transporte', value: 'TipoTransporte' },
        // { text: '¿Puedes llevar a alguien?', value: 'DisponiblesTransporte' },
        { text: '¿Cupos disponibles para transportar otros viajeros?', value: 'CuposDisponiblesTransporte' },
        { text: 'Nota Cliente', value: 'NotaCliente' },
        { text: 'Nota Interna', value: 'NotaInterna' },
        { text: 'Estado', value: 'Estado' },
        { text: 'Pago Confirmado', value: 'PagoConfirmado' },
        { text: 'Medio de contacto', value: 'MedioContacto' }
      ]

      const rows = fields.map((row) => row.text)

      worksheet.addRow(rows)

      item.Reservas.forEach((row: any) => {
        let _row = [] as any
        fields.forEach((r: any) => {
          if (r.value === 'DisponiblesTransporte' || r.value === 'PagoConfirmado') {
            _row.push(row[r.value] === true ? 'SI' : 'NO')
          } else {
            _row.push(row[r.value])
          }
        })

        worksheet.addRow(_row)
      })

      const fileName = item.Nombre + '.xlsx'
      workbook.xlsx.writeBuffer().then((data: any) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        FileSaver.saveAs(blob, fileName)
      })
    }
  }
})
