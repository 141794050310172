






















import Vue from 'vue'

/**  Model **/
import { IRutaModel } from '@/store/types'

/**  Views **/
import FormularioRuta from '@/views/Rutas/FormularioRuta.vue'

/**  Service **/
import RutaService from '@/services/RutaService'

export default Vue.extend({
  components: {
    FormularioRuta
  },

  data() {
    return {
      help: {
        title: 'Rutas Disponibles',
        subtitle: '',
        message: 'Alista tu mochila, tus zapatos viajeros y las mejores vibras para viajar con eco caminantes a lugares verdaderamente mágicos.'
      },
      ruta: {} as IRutaModel
    }
  },

  async mounted() {
    if (this.$route.params.id) {
      this.$store.dispatch('app/showLoading')
      const response = (await RutaService.findById({ Id: this.$route.params.id } as any)) as any
      this.ruta = response
      this.$store.dispatch('app/hideLoading')
    } else {
      this.$router.push('/experiencias')
    }
  }
})
