







































































































import Vue from 'vue'
import { v4 as uuidv4 } from 'uuid'

/**  Model **/
import { ICuponModel } from '@/store/types'

/**  Service **/
import CuponesService from '@/services/CuponesService'

export default Vue.extend({
  data() {
    return {
      help: {
        title: 'Cupones de descuento',
        subtitle: '',
        message: 'En esta página podrás encontrar todos los cupones de descuento que han sido creados.'
      },

      form: {
        loading: true,
        dialog: false,
        valid: false,
        record: { Activo: true, Codigo: '' } as ICuponModel,
        rules: {
          required: [(v: any) => !!v || 'Este campo es requerido'],
          porcentaje: [
            (v: number) => v >= 0 || 'El porcentaje de descuento mínimo es 0',
            (v: number) => v <= 100 || 'porcentaje de descuento máximo es 100',
            (v: any) => !!/^([0-9])*$/g.test(v) || 'Ingrese solo numeros'
          ]
        }
      },

      table: {
        loading: true,
        filters: {
          search: null,
          rol: null
        },
        headers: [
          { text: 'Código', value: 'Codigo', align: 'left' },
          { text: 'Porcentaje de descuento', value: 'Porcentaje', align: 'center' },
          { text: 'Creación', value: 'UsuarioCreacion', align: 'left' },
          { text: 'Ultima modificación', value: 'UsuarioModificacion', align: 'left' },
          { text: 'Acciones', align: 'center' }
        ],
        records: [],
        rowsPerPage: [5, 10, 20, 25, 30, { text: 'Todos', value: -1 }]
      }
    }
  },

  computed: {
    formRecord(): any {
      return this.$refs.formRecord as Vue & { validate: () => boolean; reset: () => void }
    }
  },

  mounted() {
    this.load()
  },

  methods: {
    copy(item: ICuponModel) {
      this.$copyText(item.Codigo).then(
        () => {
          this.$store.dispatch('app/alertPrompt', {
            text: 'Código copiado.',
            type: 'success'
          })
        },
        () => {
          this.$store.dispatch('app/alertPrompt', {
            text: 'No puede copiar.',
            type: 'error'
          })
        }
      )
    },

    newCode() {
      this.form.record.Codigo = this.guid()
    },

    guid() {
      let uud = uuidv4().toString()
      return uud.substring(0, 8)
    },

    async load() {
      this.$store.dispatch('app/showLoading')
      this.form.record = { Activo: true, Codigo: '' } as ICuponModel
      this.table.records = (await CuponesService.findAll()) as any
      this.$store.dispatch('app/hideLoading')
    },

    async save() {
      if (this.formRecord.validate()) {
        if (this.table.records.find((f: ICuponModel) => f.Codigo === this.form.record.Codigo)) {
          this.$store.dispatch('app/alertPrompt', {
            text: `El código ${this.form.record.Codigo} ya existe.`,
            type: 'error'
          })
        } else {
          const _confirm = await this.$store.dispatch('app/confirm', `Confirme para continuar`)

          if (_confirm !== 'OK') return

          await CuponesService.save({ ...this.form.record })

          this.$store.dispatch('app/alertPrompt', {
            text: 'Proceso realizado correctamente.',
            type: 'success'
          })

          await this.load()
        }
      }
    },

    async eliminarGaleria(item: any) {
      const _confirm = await this.$store.dispatch('app/confirm', `Confirme para continuar`)

      if (_confirm !== 'OK') return

      await CuponesService.remove(item)
      this.$store.dispatch('app/alertPrompt', {
        text: 'Proceso realizado correctamente.',
        type: 'success'
      })

      await this.load()
    }
  }
})
